import ColumnChart from './ColumnChart';

export default function ColumnChartWidget({ studentData }) {
  const categories = studentData.map(item => item.topic);
  const data = studentData.map(item => item.grade);

  return (
    <div className="report-widget">
      <div className="report-widget__header">
        <div className="report-widget__inner">
          <h3 className="report-widget__title">Série histórica de notas x temas</h3>
          <p className="report-widget__subtitle">Para a construção do gráfico, foi considerada a nota mais alta enviada para cada redação</p>
        </div>
      </div>

      <div className="report-widget__body">
        <ColumnChart
          categories={categories}
          data={data}
        />
      </div>
    </div>
  );
}
