import React from 'react';
import { FiChevronDown, FiDownload } from 'react-icons/fi';
import AccordionItemModal from './AccordionItemModal';
import xlsx from 'json-as-xlsx';
import { statusBadgeText } from 'app/screens/Essay/utils/BadgeEssay';

export default function EssaysDatagrid({ sends = [] }) {
  const handleDownload = sends => {
    const flattenedSends = sends.flatMap(send =>
      [...send.essay_students]
        .sort((a, b) => b.submission_order - a.submission_order)
        .map(essay => ({
          topic: send.topic,
          grade: essay.grade,
          competencies: essay.competencies.map(c => `${c.slug}: ${c.score}`).join(', '),
          status: statusBadgeText[essay.status] || 'Status desconhecido',
          corrector: essay.corrector || '--'
        }))
    );

    const xlsxStructure = [
      {
        sheet: 'Redações',
        columns: [
          { label: 'Tema', value: 'topic' },
          { label: 'Nota', value: 'grade' },
          { label: 'Competências', value: 'competencies' },
          { label: 'Status', value: 'status' },
          { label: 'Corretor', value: 'corrector' }
        ],
        content: flattenedSends
      }
    ];

    try {
      xlsx(xlsxStructure);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="report-widget">
      <div className="report-widget__header">
        <h3 className="report-widget__title">Redações feitas pelo estudante</h3>

        <div className="report-widget__filter">
          <button
            type="button"
            className="btn btn--outline"
            onClick={() => handleDownload(sends)}
          >
            <FiDownload className="btn__icon" /> Fazer download
          </button>
        </div>
      </div>

      <div className="report-widget__body">
        <div
          className="round-dg-wrapper u-mb-2"
          style={{ width: '100%' }}
        >
          <table style={{ width: '100%', minWidth: 966 }}>
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th className="round-dg__cell-header round-dg__cell-header--sm">Redação</th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: 54 }}
                >
                  Envios
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: 88 }}
                >
                  Entrega em
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: 154 }}
                >
                  Status
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: 294 }}
                >
                  Nota e competências
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: 160 }}
                >
                  Corretor
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="accordion">
          {sends?.map((essay, index) => (
            <AccordionItemModal
              key={index}
              essay={essay}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
