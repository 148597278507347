import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RichTextEditor from './RichTextEditor';
import EmptyState from 'app/components/EmptyState';
import NoteCard from './NoteCard';
import Loader from '../loader';
import { useNote } from 'app/hooks/useNote';

export default function PrivateNotes(props) {
  const { hasNotes, handleNewNote, setSearchTerm, notes, setActiveNote, session, activeNote } = props;
  const { loading } = useNote();

  const { t } = useTranslation();

  if (!hasNotes)
    return (
      <>
        {loading ? (
          <Loader />
        ) : (
          <EmptyState
            type="register"
            title={t('emptyState.notesTitle')}
            text={t('emptyState.notesText')}
            cta={{ text: 'Fazer anotações', onClick: handleNewNote }}
            bgless
          />
        )}
      </>
    );

  return (
    hasNotes && (
      <div className="notes__body">
        <aside className="notes__aside">
          <input
            className="form__control form__control--search-with-icon"
            aria-label={'Pesquisar'}
            placeholder={t('textsCommon.research')}
            type="search"
            name="search"
            id="search"
            onChange={e => setSearchTerm(e.target.value)}
          />
          <nav className="notes__nav">
            {notes.map(note => (
              <NoteCard
                note={note}
                index={note.id}
                key={note.id}
                activeNote={activeNote}
                setActiveNote={setActiveNote}
                user={{ image: note.user.image.url, name: note?.user?.name, id: note?.user?.id }}
              />
            ))}
          </nav>
        </aside>

        <div className="notes__view">
          <RichTextEditor note={activeNote} />
        </div>
      </div>
    )
  );
}
