import React from 'react';
import EssaySendSidebar from './EssaySendSidebar';
import EssaySendContent from './EssaySendContent';

export default function EssaySend({ essayId, studentId }) {
  return (
    <main>
      <EssaySendContent
        essayId={essayId}
        studentId={studentId}
      />
    </main>
  );
}
