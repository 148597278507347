import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { FiLogOut, FiMenu, FiX } from 'react-icons/fi';
import { useSession } from 'app/hooks/useSession';
import { ReactComponent as UserAvatar } from 'app/images/user-avatar.svg';
import { navigate } from '@reach/router';
import MenuItem from './MenuItem';
import { studentMenu } from './studentMenu';
import { adminMenu } from './adminMenu';
import HelpSubmenu from './HelpSubmenu';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';

export default function Menu(props) {
  const { hasCustomLogos } = props;

  const { session, signOut } = useSession();

  const [isExpanded, setIsExpanded] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const appName = process.env.REACT_APP_NAME;
  const isStudent = checkIncludeProfiles({ only: ['student'] });

  let favicon;

  switch (appName) {
    case 'unisescoop':
    case 'demo':
    case 'encceja':
    case 'siape':
    case 'canaleducacao':
    case 'egepi':
    case 'vegalabs':
    case 'inkluziva':
    case 'seducpi':
    case 'maiseducacao':
    case 'tjpara':
    case 'pit':
    case 'caboverde':
    case 'oros':
      favicon = `/themes/${appName}/favicon/favicon-menu-32x32.png`;
      break;

    default:
      favicon = '/themes/demo/favicon/favicon-menu-32x32.png';
      throw new Error('No REACT_APP_NAME set.');
  }

  const getAvatar = session?.user?.image?.url ? (
    <img
      className="avatar"
      src={session?.user?.image?.url}
      alt="foto do usuário"
    />
  ) : (
    <UserAvatar className="avatar" />
  );

  const menu = isStudent ? studentMenu() : adminMenu();

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={hasCustomLogos ? 'menu menu--has-custom-logos' : 'menu'}>
      <div className="menu__header">
        <img
          className="menu__favicon"
          src={favicon}
          alt="logotipo"
          onClick={() => {
            navigate('/');
            setIsExpanded(false);
          }}
        />

        <button
          className="menu__toggle-btn"
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!isExpanded ? <FiMenu /> : <FiX />}
        </button>
      </div>

      <nav
        className={isExpanded ? 'menu__nav menu__nav--is-expanded' : 'menu__nav'}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ul className={screenHeight < 625 ? 'menu__list menu__list--no-gap' : 'menu__list'}>
          {menu.map(item => (
            <MenuItem
              item={item}
              key={item.id}
            />
          ))}
        </ul>

        <ul className={screenHeight < 625 ? 'menu__list menu__list--no-gap' : 'menu__list'}>
          <li className="menu__item">
            <Link
              className="menu__link menu__link--avatar"
              to="/profile"
            >
              {getAvatar} <span className="menu__label">{session?.user?.name}</span>
            </Link>
          </li>
          <HelpSubmenu />
          <li className="menu__item">
            <a
              className="menu__link"
              onClick={() => signOut()}
            >
              <FiLogOut className="menu__icon" /> <span className="menu__label">Sair</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
