import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import toast from 'react-hot-toast';
import { uploadFileFromPresignedUrl } from 'app/utils/aws-sdk';
import { useClient } from 'jsonapi-react';
import { v4 as uuid } from 'uuid';

export default function StudentCard(props) {
  const client = useClient();
  const { student, classrooms, courses, id, getStudents } = props;
  const [studentStatus, setStudentStatus] = useState();
  const [sendExam, setSendExam] = useState(false);

  useEffect(() => {
    if (student['question-books-user']?.['answer-sheet'] === null) {
      return setStudentStatus('Realizado online');
    } else {
      setStudentStatus(student['question-books-user']?.['answer-sheet']?.['processing-status']);
    }
  }, [student]);

  // function handleSendExam(e) {
  //   const myPromise = new Promise(r => setTimeout(r, 1000));

  async function handleSendExam(e) {
    const toastLoading = toast.loading('Enviando');
    setSendExam(true);
    try {
      const filenameList = e.target.files[0].name.split('.');
      const newFileName = uuid().replaceAll('-', '') + '.' + filenameList[filenameList.lastIndex];
      const file = new File([e.target.files[0]], newFileName, { type: e.target.files[0].type });

      const { data, error } = await client.mutate(`question_books/${id}/answer_sheets/presign_s3_url`, {
        file_key: `uploads/answer_sheet/${file.name}`,
        content_type: file.type
      });

      if (error) {
        toast.error('Erro ao enviar prova');
        return;
      } else {
        const presignedUrl = data['presigned-url'];
        uploadFileFromPresignedUrl(presignedUrl, file).then(uploadResult => {
          if (uploadResult) {
            client
              .mutate(`question_books/${id}/answer_sheets`, {
                url: uploadResult,
                'question-books-user-attributes': {
                  'user-id': student.id,
                  'question-book-id': id
                }
              })
              .then(() => {
                toast.success('Prova enviada');
              });
          } else {
            toast.error('Erro ao enviar prova');
          }
        });
      }
    } catch (error) {
      toast.error('Erro ao enviar prova');
    }
    toast.dismiss(toastLoading);
    getStudents();
    setSendExam(false);
  }

  const getStudentCourses = () => {
    return courses.filter(course => student?.['course-ids'].includes(course.id));
  };

  const getStudentClassrooms = () => {
    return classrooms.filter(classroom => student?.['classroom-ids'].includes(classroom.id));
  };
  const studentClassrooms = getStudentClassrooms();
  const studentCourses = getStudentCourses();

  const examDownloadUrl = student?.['question-books-user']?.['answer-sheet']?.url;

  //pendente até descobrir os outros estados
  function getStatus(status) {
    switch (status) {
      case 'processing':
        return { text: 'Processando', badgeClass: 'warning', buttonStatus: 'disabled' };
      case 'completed':
        return { text: 'Concluido', badgeClass: 'success', buttonStatus: 'disabled' };
      case 'failed':
        return { text: 'Erro ao processar', badgeClass: 'danger', buttonStatus: '' };
      case 'Realizado online':
        return { text: 'Realizado online', badgeClass: 'success', buttonStatus: 'disabled' };
      default:
        return { text: 'Ainda não realizada', badgeClass: 'primary', buttonStatus: '' };
    }
  }

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div
          className="round-dg__user u-cursor-pointer"
          onClick={() => navigate(`/usuario/metricas/${student?.id}`, { state: { isRedirected: true } })}
        >
          <img
            className="avatar avatar--sm"
            src={student.image.url}
            alt="Imagem do usuário"
          />
          <div className="round-dg__inner-user">
            <h3 className="round-dg__title">{student.name.length > 15 ? `${student.name.slice(0, 15)}...` : student.name}</h3>
          </div>
        </div>
      </th>
      <td className="round-dg__cell">{studentCourses.length > 0 ? studentCourses.map(course => course.title).join(', ') : 'Nenhum curso'}</td>
      <td className="round-dg__cell">{studentClassrooms.length > 0 ? studentClassrooms.map(classroom => classroom.title).join(', ') : 'Nenhuma turma'}</td>
      <td className="round-dg__cell">
        <span className={`badge badge--tiny badge--${getStatus(studentStatus).badgeClass}`}>{getStatus(studentStatus).text}</span>
      </td>
      <td className="round-dg__cell">
        <label
          className={sendExam || getStatus(studentStatus).buttonStatus === 'disabled' ? 'btn btn--tiny btn--full btn--outline disabled' : 'btn btn--tiny btn--full btn--outline '}
          style={{ pointerEvents: sendExam || getStatus(studentStatus).buttonStatus === 'disabled' ? 'none' : 'all' }}
        >
          <input
            type="file"
            name="file"
            id="file"
            style={{ display: 'none' }}
            onChange={e => {
              handleSendExam(e);
            }}
          />
          Enviar prova offline
        </label>
      </td>
      <td className="round-dg__cell">
        <a
          href={student?.['question-books-user']?.['answer-sheet']?.url}
          target="_blank"
          download
          rel="noopener noreferrer"
          className={!examDownloadUrl ? 'btn btn--small btn--outline disabled' : 'btn btn--small btn--outline'}
        >
          Baixar
        </a>
      </td>
    </tr>
  );
}
