import TextareaAutosize from 'react-autosize-textarea';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';

export default function Competence({ competence, item, form, studentEssay, switchDigital, index }) {
  const [comment, setComment] = useState('');

  function valuesToArray(obj) {
    return Object.keys(obj).map(function (key) {
      return obj[key];
    });
  }

  const checkScore = (score, maxScore) => {
    const oneThird = maxScore / 3;
    const twoThirds = oneThird * 2;

    if (score <= oneThird) {
      return 'danger';
    } else if (score > oneThird && score <= twoThirds) {
      return 'warning';
    } else if (score > twoThirds) {
      return 'success';
    }
  };

  const competenceSelected = form?.values.essay_student_competencies_attributes?.find(item => item?.slug === competence.slug);

  const getOriginalComment = competencyId => {
    return valuesToArray(studentEssay?.competencies_list?.[competenceSelected?.name]).find(comp => comp.id === Number(competencyId))?.comment;
  };

  useEffect(() => {
    setComment(studentEssay?.competencies[index].comment);
  }, []);

  return (
    <div className={`${styles['competences-card']} u-mt-3`}>
      <span className={styles['competences-card-title']}>{item.name}</span>
      <h2 className={`${styles['competences-card-name']} u-mb-2`}>{item.description}</h2>
      <div className={styles['competences-card-level']}>
        <select
          id="level"
          name="level"
          className="form__select"
          value={form?.values?.essay_student_competencies_attributes?.[index]?.competency_id}
          onChange={e => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            const selectedScore = selectedOption.getAttribute('data-score');
            const selectedCompetencyId = e.target.value;
            form.setFieldValue(`essay_student_competencies_attributes[${index}].competency_id`, selectedCompetencyId);
            form.setFieldValue(`essay_student_competencies_attributes[${index}].score`, parseInt(selectedScore));
            setComment(getOriginalComment(selectedCompetencyId));
            form.setFieldValue(`essay_student_competencies_attributes[${index}].comment`, getOriginalComment(selectedCompetencyId));
          }}
        >
          {valuesToArray(studentEssay?.competencies_list?.[competenceSelected?.name])?.map((item, index) => {
            return (
              <option
                key={item.id}
                value={item.id}
                data-score={item?.score}
              >
                Nível {index}
              </option>
            );
          })}
        </select>
        <TextareaAutosize
          name="commentary"
          style={{ marginTop: 8 }}
          value={comment}
          onChange={e => {
            setComment(e.target.value);
            form.setFieldValue(`essay_student_competencies_attributes[${index}].comment`, e.target.value);
          }}
        />
      </div>
      <span className={`square-tag square-tag--full square-tag--md square-tag--${checkScore(competence?.score, competence?.['max_grade'])} ${styles['competences-card-score']}`}>
        <strong>{competence?.score}</strong>/{competence?.['max_grade']} pts
      </span>
    </div>
  );
}
