import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { DatasetComponent, TooltipComponent, VisualMapComponent } from 'echarts/components';
import { CustomChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { pack, stratify } from 'd3-hierarchy';

echarts.use([DatasetComponent, TooltipComponent, VisualMapComponent, CustomChart, CanvasRenderer]);

export const ReportsChart = ({ report = [], setSelectedRegional = null, setShowModule = null, selectedRegional }) => {
  const chartRef = useRef(null);
  const [option, setOption] = useState({});

  function getDefaultColors() {
    switch (process.env.REACT_APP_NAME) {
      case 'demo':
        return {
          primary: '#442B8C',
          primaryLight: '#7763AE'
        };
      case 'siape':
        return {
          primary: '#008400',
          primaryLight: '#51D151'
        };
      case 'iesb':
        return {
          primary: '#D7031A',
          primaryLight: '#FD8C98'
        };
      case 'oab':
        return {
          primary: '#442B8C',
          primaryLight: '#7763AE'
        };
      case 'quartosecretariado':
        return {
          primary: '#2B61AF',
          primaryLight: '#758BAC'
        };
      case 'retina':
        return {
          primary: '#0099B2',
          primaryLight: '#81B4BC'
        };
      case 'fic':
        return {
          primary: '#662483',
          primaryLight: '#A374B8'
        };
      case 'brasilstartups':
        return {
          primary: '#0C2662',
          primaryLight: '#408DEB'
        };
      case 'canaleducacao':
        return {
          primary: '#0F79B0',
          primaryLight: '#61B1DB'
        };
      case 'egepi':
        return {
          primary: '#204D9C',
          primaryLight: '#61B1DB'
        };
      case 'vegalabs':
        return {
          primary: '#FF6B00',
          primaryLight: '#DBA680'
        };
      case 'inkluziva':
        return {
          primary: '#3EA4F5',
          primaryLight: '#68A6D8'
        };
      case 'seducpi':
        return {
          primary: '#254AA5',
          primaryLight: '#68A6D8'
        };
      case 'maiseducacao':
        return {
          primary: '#254AA5',
          primaryLight: '#8EA7E4'
        };
      case 'tjpara':
        return {
          primary: '#2F2F92',
          primaryLight: '#8888C8'
        };
      case 'powerforgirls':
        return {
          primary: '#E84600',
          primaryLight: '#FF9466'
        };
      case 'caboverde':
        return {
          primary: '#442B8C',
          primaryLight: '#7B63BF'
        };
      case 'encceja':
        return {
          primary: '#054F9C',
          primaryLight: '#84AFDB'
        };
      case 'oros':
        return {
          primary: '#bb8538',
          primaryLight: '#222529'
        };
      default:
        throw new Error('No REACT_APP_NAME set.');
    }
  }

  function initChart(seriesData) {
    function stratifyData() {
      const formattedData = stratify()
        .parentId(function (d) {
          return d.id.substring(0, d.id.lastIndexOf('.'));
        })(seriesData)
        .sum(function (d) {
          return d.value || 0;
        })
        .sort(function (a, b) {
          return b.value - a.value;
        });

      return formattedData;
    }

    var displayRoot = stratifyData();

    function overallLayout(params, api) {
      var context = params.context;
      pack()
        .size([api.getWidth() - 2, api.getHeight() - 2])
        .padding(3)(displayRoot);
      context.nodes = {};
      displayRoot.descendants().forEach(function (node, index) {
        context.nodes[node.id] = node;
      });
    }

    function renderItem(params, api) {
      const context = params.context;
      // Only do that layout once in each time `setOption` called.
      if (!context.layout) {
        context.layout = true;
        overallLayout(params, api);
      }
      const nodePath = api.value('id');
      let node = context.nodes[nodePath];
      if (!node) return;

      const isLeaf = !node.children || !node.children.length;
      const focus = new Uint32Array(
        node.descendants().map(function (node) {
          return node.data.name;
        })
      );

      const nodeName = isLeaf
        ? nodePath
            .slice(nodePath.lastIndexOf('.') + 1)
            .split(/(?=[A-Z][^A-Z])/g)
            .join('\n')
        : '';
      const z2 = api.value('depth') * 2;

      return {
        type: 'circle',
        focus,
        shape: {
          cx: node.x,
          cy: node.y,
          r: node.r
        },
        transition: ['shape'],
        z2,
        textContent: {
          type: 'text',
          style: {
            transition: isLeaf ? 'fontSize' : null,
            text: nodeName,
            fontFamily: 'Nunito',
            width: node.r * 1.3,
            overflow: 'truncate',
            fontSize: node.r / 4,
            fontWeight: 700
          },
          emphasis: {
            style: {
              overflow: null,
              fontSize: Math.max(node.r / 3, 12)
            }
          }
        },
        textConfig: {
          position: 'inside'
        },
        style: {
          fill: api.visual('color')
        },
        emphasis: {
          style: {
            fontFamily: 'Nunito',
            fontSize: 10,
            shadowBlur: 20,
            shadowOffsetX: 3,
            shadowOffsetY: 5,
            shadowColor: 'rgba(0,0,0,0.3)'
          }
        }
      };
    }

    const optionData = {
      dataset: {
        source: seriesData
      },
      tooltip: {
        show: true,
        trigger: 'item',
        backgroundColor: 'white',
        textStyle: {
          color: 'var(--color-primary)',
          fontStyle: 'normal',
          fontWeight: 700,
          fontFamily: 'Nunito',
          fontSize: 14
        }
      },
      visualMap: {
        show: false,
        min: -1,
        max: 5,
        dimension: 'depth',
        inRange: {
          color: [getDefaultColors().primary, getDefaultColors().primaryLight]
        }
      },
      hoverLayerThreshold: Infinity,
      series: {
        type: 'custom',
        renderItem: renderItem,
        progressive: 0,
        coordinateSystem: 'none',
        encode: {
          tooltip: 'count',
          itemName: 'name'
        }
      }
    };

    setOption(optionData);

    const myChart = echarts.init(chartRef.current.ele);

    function drillDown(targetNodeId) {
      displayRoot = stratifyData();

      if (targetNodeId != null) {
        displayRoot = displayRoot.descendants().find(function (node) {
          return node.data.id === targetNodeId;
        });
      }
      displayRoot.parent = null;

      myChart.setOption({
        dataset: {
          source: seriesData
        }
      });
    }

    myChart.on('click', { seriesIndex: 0 }, function (params) {
      drillDown(params.data.id);
    });

    // Reset: click on the blank area.
    myChart.getZr().on('click', function (event) {
      if (!event.target) {
        drillDown();
      }
    });
  }

  function prepareData() {
    let seriesData = [];

    function convert() {
      if (report == null) {
        return;
      }

      const listChartOptions = [
        {
          depth: 0,
          id: 'option',
          index: 0,
          value: undefined,
          name: undefined,
          count: undefined,
          item: null
        }
      ];

      report.map((item, index) => {
        listChartOptions.push({
          id: `option.${item.name.replace(/[.]/g, '')}`,
          depth: 2,
          index: index,
          value: item.registrationsCount === 0 ? 1 : item.registrationsCount,
          name: item.name,
          count: `${item.registrationsCount} usuários`,
          item: item
        });
      });

      seriesData = listChartOptions;
    }

    convert();

    return {
      seriesData
    };
  }

  function run() {
    const dataWrap = prepareData();
    initChart(dataWrap.seriesData);
  }

  useEffect(() => {
    if (report.length > 0 && chartRef.current) {
      run();
    }
  }, [report, chartRef.current]);


  const onChartClick = params => {
    if (!selectedRegional) {
      setSelectedRegional(params?.data?.item);
      setShowModule(true);
    }
  };

  const onEvents = {
    click: onChartClick
  };

  return (
    <ReactECharts
      ref={chartRef}
      option={option}
      echarts={echarts}
      style={{ height: 768 }}
      onEvents={onEvents}
    />
  );
};
