import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { useState, useEffect } from 'react';

export default function ColumnChart({ categories, data }) {
  const [screenWidth, setscreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setscreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  HC_more(Highcharts);

  const options = {
    chart: {
      type: 'column',
      marginBottom: 100
    },
    credits: {
      enabled: false
    },
    title: {
      text: 'Notas por Tópicos'
    },
    xAxis: {
      categories: categories, // Tópicos como categorias
      labels: {
        style: {
          whiteSpace: 'nowrap',
          width: 160,
          textOverflow: 'ellipsis'
        }
      }
    },
    yAxis: {
      min: 0,
      max: 1000,
      title: {
        text: 'Nota'
      }
    },
    series: [
      {
        name: 'Notas',
        data: data,
        color: '#7cb5ec'
      }
    ],
    legend: {
      enabled: false
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true
        }
      }
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}
