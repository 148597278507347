import React from 'react';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import LessonCard from './LessonCard';

export default function TabLessons({ currentModule }) {
  const filters = useFormik({
    initialValues: {
      searchTerm: '',
      discipline: 'blank'
    }
  });

  const lessons = currentModule?.['learning-system-contents']?.filter(item => item?.learning_system_items?.length > 0);
  const learningSystemItems = lessons?.flatMap(item => (item?.learning_system_items?.length > 0 ? item?.learning_system_items.map(learningSystemItem => ({ ...learningSystemItem, discipline_name: item.body })) : []));

  return (
    <div className="fadeIn">
      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              htmlFor="search"
              className="filter-bar__label"
            >
              {t('textsCommon.search')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder="Pesquisar por aula"
              type="search"
              name="search"
              id="search"
              onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
            />
          </div>

          <FilterSelectionBox
            blankLabel={t('filter.blankLabelAll2')}
            label={'Selecione uma disciplina'}
            value={filters.values.discipline}
            onChange={e => filters.setFieldValue('status', e.target.value)}
            options={[]}
          />
        </div>
      </div>

      <div className="round-dg-wrapper">
        <table
          className="round-dg round-dg--light"
          style={{ minWidth: '800px' }}
        >
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th className="round-dg__cell-header">Nome da aula</th>
              <th className="round-dg__cell-header">Aula</th>
              <th
                className="round-dg__cell-header"
                style={{ width: '132px' }}
              >
                Arquivos da aula
              </th>
              {/* <th
                className="round-dg__cell-header"
                style={{ width: '170px' }}
              >
                Plano de aula
              </th> */}
              <th
                className="round-dg__cell-header"
                style={{ width: '94px' }}
              >
                Duração
              </th>
              <th
                className="round-dg__cell-header"
                style={{ width: '156px' }}
              />
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {learningSystemItems?.length > 0 &&
              learningSystemItems?.map(item => (
                <LessonCard
                  key={item.id}
                  data={item}
                  moduleId={currentModule.id}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
