import { get } from 'draft-js/lib/DefaultDraftBlockRenderMap';
import styles from './CompetencesModal.module.scss';
import EmptyState from 'app/components/EmptyState';

export default function CompetenciesModal({ sentDetails }) {
  const getCompetencieDetails = competencie => {
    switch (competencie?.slug) {
      case 'c1':
        return {
          name: 'Competência 1',
          title: 'Demonstrar domínio da modalidade escrita formal da língua portuguesa.',
          description: 'Demonstrar domínio da modalidade escrita formal da língua portuguesa.',
          maxScore: competencie?.['max-grade'],
          score: competencie?.score,
          comment: competencie?.comment
        };
      case 'c2':
        return {
          name: 'Competência 2',
          title: 'Compreensão e Aplicação Interdisciplinar do Tema Proposto',
          maxScore: competencie?.['max-grade'],
          description: 'Compreender a proposta de redação e aplicar conceitos das várias áreas de conhecimento para desenvolver o tema dentro dos limites estruturais do texto dissertativo-argumentativo em prosa.',
          score: competencie?.score,
          comment: competencie?.comment
        };
      case 'c3':
        return {
          name: 'Competência 3',
          title: 'Seleção e Organização Lógica de Argumentos',
          description: 'Selecionar, relacionar, organizar e interpretar informações, fatos, opiniões e argumentos em defesa de um ponto de vista.',
          maxScore: competencie?.['max-grade'],
          score: competencie?.score,
          comment: competencie?.comment
        };
      case 'c4':
        return {
          name: 'Competência 4',
          title: 'Construção Coesa e Coerente da Argumentação',
          description: 'Demonstrar conhecimento dos mecanismos linguísticos necessários para a construção da argumentação.',
          maxScore: competencie?.['max-grade'],
          score: competencie?.score,
          comment: competencie?.comment
        };
      case 'c5':
        return {
          name: 'Competência 5',
          title: 'Proposição de Intervenção Respeitosa aos Direitos Humanos',
          description: 'Elaborar proposta de intervenção para o problema abordado, respeitando os direitos humanos.',
          maxScore: competencie?.['max-grade'],
          score: competencie?.score,
          comment: competencie?.comment
        };
    }
  };

  const competencies = sentDetails?.competencies.map(item => getCompetencieDetails(item));

  const checkCompetenceScore = (score, maxScore) => {
    const oneThird = maxScore / 3;
    const twoThirds = oneThird * 2;

    if (score <= oneThird) {
      return styles.dangerText;
    } else if (score > oneThird && score <= twoThirds) {
      return styles.warningText;
    } else if (score > twoThirds) {
      return styles.successText;
    }
  };

  const checkTotalScore = score => {
    if (score <= 333) {
      return 'danger';
    } else if (score > 333 && score <= 666) {
      return 'warning';
    } else if (score > 666) {
      return 'success';
    }
  };

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <h6 className={styles.title}>Detalhamento das competências</h6>
            <p className={styles.info}>Redação: {sentDetails?.essay?.topic}</p>
            {sentDetails?.corrector && <p className={styles.info}>Corrigido por {sentDetails?.corrector}</p>}
          </div>
          <div className={`${styles.score} ${checkTotalScore(sentDetails?.grade) === 'success' ? styles.bgSuccess : checkTotalScore(sentDetails?.grade) === 'warning' ? styles.bgWarning : styles.bgDanger}`}>
            <span style={{ fontSize: 24 }}>{sentDetails?.grade}</span> <br />
            <span>pontuação final</span>
          </div>
        </div>
      </div>
      {competencies?.length === 0 && <EmptyState text={<span>Nenhuma competência avaliada</span>} />}
      {competencies?.map(competencie => (
        <div
          className="card"
          style={{ marginBottom: 12 }}
        >
          <div className={styles.competencieHeader}>
            <div>
              <span className="card__title">
                {competencie?.name}: {competencie.title}
              </span>
              <span className={styles.competencieDescription}>{competencie?.description}</span>
            </div>
            <span className={checkCompetenceScore(competencie?.score, competencie?.maxScore)}>
              <b>{competencie?.score}</b> /{competencie?.maxScore} pts
            </span>
          </div>
          <span
            className="badge"
            style={{ fontSize: 14, fontWeight: 500, padding: 8, borderRadius: 8 }}
          >
            Nível {competencie?.level}: {competencie?.comment}
          </span>
        </div>
      ))}
    </>
  );
}
