import Avatar from 'app/components/Avatar';
import './student-modal.scss';
import RadarChartWidget from '../../RadarChartWidget';
import { useClient } from 'jsonapi-react';
import { useEffect, useState } from 'react';
import OverviewStudent from './OverviewStudent';
import ColumnChartWidget from './ColumnChartWidget';
import EssaysDatagrid from './EssaysDatagrid';
import { useParams } from '@reach/router';

export default function StudentPage({ student }) {
  const params = useParams();
  const client = useClient();
  const [studentData, setStudentData] = useState(null);
  const fetchStudentData = async () => {
    const { data, error } = await client.fetch(`reports/essays/users/${params.studentId}`);
    if (error) {
      console.error('Erro ao buscar dados do aluno');
    }
    return data;
  };

  useEffect(() => {
    if (!studentData)
      fetchStudentData().then(data => {
        const kpis = setKpis(data);
        setStudentData({ ...data, kpis });
      });
  }, [studentData]);

  const setKpis = data => {
    return [
      {
        id: 1,
        title: 'Redações enviadas',
        value: data?.['total-sends'] ?? 0,
        help: 'Redações enviadas.',
        variation: 'report-kpi-card--edit'
      },
      {
        id: 2,
        title: 'Pontuação média',
        value: data?.['average-grade'] ?? 0,
        help: 'Pontuação média.',
        variation: 'report-kpi-card--file'
      }
    ];
  };

  return (
    <>
      <div
        className="modal-header"
        style={{ borderRadius: 0 }}
      ></div>
      <div className="user-info">
        <Avatar
          className="pic"
          src={studentData?.src}
        />
        <div className="container">
          <h3 className="title">{studentData?.user?.name || 'Carregando ...'}</h3>
          <span className="subtitle">{studentData?.['classroom-name']}</span>
          <span className="subtitle">{studentData?.['institution-name']}</span>
        </div>
      </div>
      <div className="report-container">
        <OverviewStudent kpis={studentData?.kpis || []} />
        <RadarChartWidget apiData={studentData?.['average-by-competence']} />
        <ColumnChartWidget studentData={studentData?.history || []} />
        <EssaysDatagrid sends={studentData?.sends} />
      </div>
    </>
  );
}
