import { Link } from '@reach/router';
import Modal, { SimpleModal } from 'app/components/Modal';
import { VimeoWithSurvey } from 'app/components/VimeoWithSurvey';
import { YoutubeWithSurvey } from 'app/components/YoutubeWithSurvey';
import moment from 'moment';
import { useEffect, useState } from 'react';

export default function FullScreenModal({ visible, onClose, videoPlatform, timeLeft, nextClassStarted, videoId, classData, showModal, setShowModal, answers }) {
  useEffect(() => {
    const liveClassModal = document.querySelector('.simple-modal__content--embed-video');

    if (visible && liveClassModal) {
      liveClassModal.requestFullscreen().catch(err => {
        console.error('Erro ao entrar em fullscreen:', err);
      });
    }

    return () => {
      if (document.fullscreenElement) {
        document.exitFullscreen().catch(err => {
          console.error('Erro ao sair do fullscreen:', err);
        });
      }
    };
  }, [visible]);

  return (
    <SimpleModal
      show={visible}
      onClose={onClose}
      contentClassName="simple-modal__content--full simple-modal__content--embed-video"
    >
      <div className="live-class-player__embed">
        {videoPlatform && videoPlatform === 'youtube' && (
          <YoutubeWithSurvey
            showModalSurvey={showModal}
            setShowModalSurvey={setShowModal}
            videoId={videoId}
            answers={answers}
          />
        )}
        {videoPlatform && videoPlatform === 'vimeo' && (
          <VimeoWithSurvey
            videoId={videoId}
            setShowModalSurvey={setShowModal}
            showModalSurvey={showModal}
          />
        )}
        {timeLeft >= 0 && videoPlatform && videoPlatform === 'iframe' && (
          <iframe
            src={videoId}
            scrolling="no"
          ></iframe>
        )}
        {/* {nextClassStarted && (*/}
        {/*  <div className={`live-class-player_overlay ${timeLeft <= 0 ? 'live-class-player_overlay--noise' : ''}`}>*/}
        {/*    <div className="live-class-player_overlay__inner">*/}
        {/*      <div className="live-class-player_overlay__title">Sua próxima aula já começou</div>*/}

        {/*      <div className="live-class-player_overlay__body">{timeLeft >= 0 ? `Esta transmissão será encerrada em ${moment().startOf('day').seconds(timeLeft).format('mm:ss')}` : 'Clique no botão abaixo para assistir'}</div>*/}
        {/*      <Link*/}
        {/*        to={`/aulas-ao-vivo/${classData?.['next-live-classroom']?.id}`}*/}
        {/*        className="btn btn--outline btn--small btn--wide"*/}
        {/*      >*/}
        {/*        Próxima aula*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)} */}
      </div>
    </SimpleModal>
  );
}
