import React, { useEffect, useState } from 'react';
import MainNav from 'app/components/mainNav';
import Menu from 'app/components/Menu';
import CpfModal from 'app/components/CpfModal';
import { useSession } from 'app/hooks/useSession';
import PtsModal from 'app/screens/Users/PtsModal';
import ActionCableConsumer from 'app/utils/actionCableConsumer';
import DuelNotification from 'app/components/DuelNotification';
import NoClassroomModal from 'app/components/NoClassroomModal';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { LogosSeduc } from 'app/components/LogosSeduc/LogosSeduc';
import styles from './PageWithLayouts.module.scss';

function PageWithLayouts(props) {
  const { layoutType, children } = props;
  const [showModal, setShowModal] = useState(false);
  const { session } = useSession();
  const [achievementData, setAchievementData] = useState(null);
  const [duelData, setDuelData] = useState(null);

  const reviwedData = session?.user?.reviewedInfos;

  //noclassroom modal
  const client = useClient();
  const currentUser = session?.user;
  const [show, setShow] = useState(false);
  const [studentClassrooms, setStudentClassrooms] = useState();

  const renderCPF = currentUser?.cpfCnpj === null || currentUser?.cpfCnpj === '';
  const renderClassrooms = studentClassrooms?.length === 0 || studentClassrooms?.some(e => ['turma única', 'sem turma', 'sem escola'].includes(e.title.toLowerCase()) && !['sem escola', 'já sou formado'].includes(e.institution.name.toLowerCase()));

  const appName = process.env.REACT_APP_NAME;
  const hasCustomLogos = appName === 'canaleducacao' || appName === 'maiseducacao';

  useEffect(() => {
    const openModal = !reviwedData && currentUser?.profileNames[0] === 'Aluno' && (renderCPF || renderClassrooms);
    setShow(openModal);
  }, [studentClassrooms, currentUser]);

  async function getStudentClassroom() {
    const { data, error } = await client.fetch('classrooms');
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setStudentClassrooms(data);
    }
  }

  useEffect(() => {
    if (session && currentUser?.profileNames[0] === 'Aluno') {
      getStudentClassroom();
    }
  }, [session]);

  return (
    <div className={layoutType === 'mainLayout' ? 'main-screen' : 'main-screen main-screen--admin'}>
      {layoutType === 'mainLayout' && session && (
        <>
          <LogosSeduc hasCustomLogos={hasCustomLogos} />
          <Menu hasCustomLogos={hasCustomLogos} />
          <MainNav hasCustomLogos={hasCustomLogos} />
          <NoClassroomModal
            renderCPF={renderCPF}
            renderClassrooms={renderClassrooms}
            show={show}
            setShow={setShow}
            currentUser={currentUser}
          />
          <CpfModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <PtsModal
            achievementData={achievementData}
            setAchievementData={setAchievementData}
          />
          <DuelNotification
            duelData={duelData}
            setDuelData={setDuelData}
          />
          <ActionCableConsumer
            channel="UserMilestoneChannel"
            setObjectChannel={setAchievementData}
            channelKey={'user_id'}
            channelId={session?.user?.id}
          />
          <ActionCableConsumer
            channel="DuelNotificationsChannel"
            setObjectChannel={setDuelData}
            token={session.user.authToken}
          />
        </>
      )}

      <div className={hasCustomLogos ? `${styles.pageWithLayouts} ${styles.hasCustomLogos}` : `${styles.pageWithLayouts}`}>{children}</div>
    </div>
  );
}

export default PageWithLayouts;
