import React, { useEffect, useState } from 'react';
import { FiPlusCircle, FiMoreHorizontal } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import MatterModal from './MatterModal';
import QuestionModal from './QuestionModal';

export default function MatterBtn(props) {
  const { text, subTaxonomies, form, matterIndex, challenge, setDeletedQuestions, taxonomies, matter } = props;
  const [showModalMatter, setShowModalMatter] = useState(false);
  const [showModalQuestion, setShowModalQuestion] = useState(false);
  const [hasMatterSelected, setHasMatterSelected] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setHasMatterSelected(false);
  }, [form.values['taxonomy-id']]);

  useEffect(() => {
    if (form.values.matters[matterIndex]['taxonomy-id']) {
      setHasMatterSelected(true);
    }
  }, [form.values.matters[matterIndex]['taxonomy-id']]);

  const resetMatter = () => {
    setHasMatterSelected(false);
    form.setFieldValue(`matters[${matterIndex}]`, { 'taxonomy-id': '', 'taxonomy-name': `Matéria ${matterIndex + 1}`, questions: [] });
  };

  return (
    <>
      <span
        className="matter-btn"
        onClick={() => (!hasMatterSelected ? setShowModalMatter(true) : setShowModalQuestion(true))}
      >
        <span className="matter-btn__header">
          {!hasMatterSelected && <FiPlusCircle className="matter-btn__icon" />}
          {!hasMatterSelected ? <span className="matter-btn__title">{text}</span> : <span className="matter-btn__title">{form.values?.['matters'][matterIndex]?.['taxonomy-name']}</span>}
        </span>

        <span className="matter-btn__text">
          {form?.values?.matters?.[matterIndex]?.questions?.length > 0
            ? form?.values?.matters?.[matterIndex]?.questions?.length > 1
              ? form?.values?.matters?.[matterIndex]?.questions?.length + ' perguntas'
              : form?.values?.matters?.[matterIndex]?.questions?.length + ' pergunta'
            : 'Adicionar perguntas'}
        </span>

        <Dropdown>
          <Dropdown.Toggle>
            <span className="card__icon-wrapper card__icon-wrapper--sm">
              <FiMoreHorizontal />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Content>
            {matter['taxonomy-id'] && <Dropdown.Item onClick={resetMatter}>Remover matéria</Dropdown.Item>}
            {matter.questions.length === 0 ? (
              <>
                <Dropdown.Item onClick={() => setShowModalMatter(true)}>Editar matéria</Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setShowForm(true);
                    setShowModalQuestion(true);
                  }}
                >
                  Adicionar pergunta
                </Dropdown.Item>
              </>
            ) : (
              <>
                <Dropdown.Item
                  onClick={() => {
                    setShowForm(true);
                    setShowModalQuestion(true);
                  }}
                >
                  Adicionar pergunta
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowModalQuestion(true)}>Ver perguntas</Dropdown.Item>
              </>
            )}
          </Dropdown.Content>
        </Dropdown>
      </span>

      <MatterModal
        subTaxonomies={subTaxonomies}
        show={showModalMatter}
        onClose={() => setShowModalMatter(false)}
        text={text}
        setShowModal={setShowModalMatter}
        setHasMatterSelected={setHasMatterSelected}
        form={form}
        matterIndex={matterIndex}
      />

      <QuestionModal
        setShowForm={setShowForm}
        showForm={showForm}
        taxonomies={taxonomies}
        setDeletedQuestions={setDeletedQuestions}
        challenge={challenge}
        matterIndex={matterIndex}
        show={showModalQuestion}
        onClose={() => {
          setShowModalQuestion(false);
        }}
        text={form.values?.['matters'][matterIndex]?.['taxonomy-name']}
        setShowModal={setShowModalQuestion}
        form={form}
      />
    </>
  );
}

export function MatterBtnWrapper(props) {
  const { children } = props;

  return <div className="matter-btn-wrapper">{children}</div>;
}
