import React, { useEffect, useState } from 'react';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from 'app/screens/Login/Login.module.scss';
import Button, { ButtonWrapper } from 'app/components/Button/Button';
import { FormControl, FormField, FormFieldGrade, FormFieldGradeWrapper, FormLabel, FormRow, FormStep, FormFeedback, FormCustomSelect, FormTerms } from 'app/components/Form/Form';
import PasswordStrength from 'app/components/PasswordStrength';
import { useParams, navigate } from '@reach/router';
import { useMask } from '@react-input/mask';
import { LoginLayout } from 'app/screens/Login/Login';
import { validateCPF } from 'app/utils/validateCPF';
import toast from 'react-hot-toast';
import api from 'app/services/api';
import { createStudent, formatStudentData } from 'app/services/auth';

export const checkEmail = async email => {
  try {
    return await api.post(`/users/check_email?email=${email}`);
  } catch (error) {
    return error.response;
  }
};

const nameSchema = Yup.object().shape({
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('cpf', 'CPF Inválido', value => validateCPF(value)),
  name: Yup.string().required('Campo obrigatório')
});

const emailSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'E-mails não coincidem')
    .email('E-mail inválido')
    .required('Campo obrigatório')
});

const passwordSchema = Yup.object().shape({
  password: Yup.string().required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não coincidem')
    .required('Campo obrigatório')
});

const cellPhoneSchema = Yup.object().shape({
  cellPhone: Yup.string()
    .required('Campo obrigatório')
    .matches(/^\([1-9]{2}\) [9]{1}[0-9]{4}-[0-9]{4}$/, 'Telefone inválido')
});

const termsSchema = Yup.object().shape({
  terms: Yup.string().required('É necessário aceitar os termos.')
});

export default function SimpleSignUp() {
  const [formValues, setFormValues] = useState({});
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [passwordIsStrong, setPasswordIsStrong] = useState(false);
  const [errors, setErrors] = useState({});

  const params = useParams();
  const link = params?.link;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const ssoClient = process.env.REACT_APP_SSO_CLIENT_URL;

  const uregisteredParam = urlSearchParams.get('unregistered');
  const doneParam = urlSearchParams.get('done');

  if (uregisteredParam && !localStorage.getItem('unregistered')) localStorage.setItem('unregistered', uregisteredParam);
  if (doneParam && !localStorage.getItem('done')) localStorage.setItem('done', doneParam);

  const uregistered = localStorage.getItem('unregistered') ?? false;
  const done = localStorage.getItem('done') ?? false;

  const { formStep } = useParams();
  const cfpRef = useMask({
    mask: '___.___.___-__',
    replacement: { _: /\d/ }
  });
  const cellPhoneRef = useMask({
    mask: '(__) _____-____',
    replacement: { _: /\d/ }
  });
  const passwordRef = React.createRef();
  const confirmPasswordRef = React.createRef();

  const handleNext = async (values, actions) => {
    actions.setSubmitting(false);
    setErrors({});
    setFormValues(prevValues => ({ ...prevValues, ...values }));

    if (formStep === 'nome') {
      link ? navigate(`/criar-uma-conta/${link}/aluno/email`) : navigate('/criar-uma-conta/aluno/email');
    }
    if (formStep === 'email') {
      const email = values.email;
      const response = await checkEmail(email);

      const { found } = response;

      if (found) {
        actions.setFieldError('email', 'Este email já está cadastrado. Termine seu cadastro com um email diferente ou solicite nova senha para este email.');
        actions.setFieldError('confirmEmail', 'Este email já está cadastrado. Termine seu cadastro com um email diferente ou solicite nova senha para este email.');
        return;
      }
      link ? navigate(`/criar-uma-conta/${link}/aluno/senha`) : navigate('/criar-uma-conta/aluno/senha');
    }
    if (formStep === 'senha') {
      link ? navigate(`/criar-uma-conta/${link}/aluno/telefone`) : navigate('/criar-uma-conta/aluno/telefone');
    }
    if (formStep === 'telefone') {
      link ? navigate(`/criar-uma-conta/${link}/aluno/termos`) : navigate('/criar-uma-conta/aluno/termos');
    }
    if (formStep === 'termos') {
      toast.loading('Enviando dados...');
      const studentFormated = formatStudentData({ ...formValues, link });
      try {
        let response;
        if (link !== 'aluno') {
          response = await createStudent(studentFormated);
        } else {
          response = await createStudent(studentFormated);
        }
        toast.dismiss();
        if (response.data) {
          toast.success('Cadastro realizado com sucesso');
          link ? navigate(`/criar-uma-conta/${link}/aluno/conta-criada`) : navigate('/criar-uma-conta/aluno/conta-criada');
        }
      } catch (error) {
        toast.dismiss();
        toast.error('Erro ao enviar dados. Tente novamente mais tarde.');
      } finally {
        if (uregistered) localStorage.removeItem('unregistered');
        if (done) localStorage.removeItem('unregistered');
        localStorage.removeItem('done');
        actions.setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (formStep !== 'conta-criada' && link) {
      navigate(`/criar-uma-conta/${link}/aluno/nome`);
    } else if (formStep !== 'conta-criada' && !link) {
      navigate(`/criar-uma-conta/aluno/nome`);
    }
  }, []);

  return (
    <LoginLayout>
      <FormStep show={formStep === 'nome'}>
        <Formik
          initialValues={{ cpf: formValues.cpf ?? '', name: formValues.name ?? '' }}
          validationSchema={nameSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Siga preenchendo os dados básicos</h1>

                  <FormRow>
                    <FormLabel htmlFor="cpf">CPF</FormLabel>
                    <FormControl
                      id="cpf"
                      name="cpf"
                      type="text"
                      value={props.values.cpf}
                      invalid={props.errors.cpf && props.touched.cpf}
                      placeholder="000.000.000-00"
                      ref={cfpRef}
                    />
                    <ErrorMessage name="cpf">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                  </FormRow>
                  <FormRow>
                    <FormLabel htmlFor="name">*Nome</FormLabel>
                    <FormControl
                      id="name"
                      name="name"
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.name}
                      placeholder="Insira seu nome completo"
                    />
                  </FormRow>
                </div>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to="/login"
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.name}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'email'}>
        <Formik
          initialValues={{ email: formValues.email, confirmEmail: formValues.confirmEmail ?? '' }}
          validationSchema={emailSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Preencha com seu email</h1>
                  <p className={`${styles.description} ${styles.box} `}>Toda a comunicação da plataforma será feita pelo email que você cadastrar. Anote-o para não esquecer.</p>
                </div>

                <FormRow>
                  <FormLabel htmlFor="email">*Email</FormLabel>
                  <FormControl
                    id="email"
                    name="email"
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                    invalid={props.errors.email && props.touched.email}
                    placeholder="Insira seu e-mail"
                  />
                  <ErrorMessage name="email">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                  {errors?.email && <ErrorMessage name="email">{<FormFeedback invalid={true}>{errors.email}</FormFeedback>}</ErrorMessage>}
                </FormRow>
                <FormRow>
                  <FormLabel htmlFor="confirmEmail">*Confirme seu email</FormLabel>
                  <FormControl
                    id="confirmEmail"
                    name="confirmEmail"
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.confirmEmail}
                    invalid={props.errors.confirmEmail && props.touched.confirmEmail}
                    placeholder="Insira seu e-mail"
                  />
                  <ErrorMessage name="confirmEmail">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                  {errors?.email && <ErrorMessage name="email">{<FormFeedback invalid={true}>{errors.email}</FormFeedback>}</ErrorMessage>}
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to={link ? `/criar-uma-conta/${link}/aluno/nome` : '/criar-uma-conta/aluno/nome'}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.email || !props.values.confirmEmail}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'senha'}>
        <Formik
          initialValues={{ password: formValues.password ?? '', showPassword: formValues.showPassword ?? false, confirmPassword: formValues.confirmPassword ?? '', showConfirmPassword: formValues.showConfirmPassword ?? false }}
          validationSchema={passwordSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Crie uma senha para cadastro.</h1>
                </div>

                <FormRow>
                  <FormLabel htmlFor="password">*Senha</FormLabel>
                  <PasswordStrength
                    show={showPasswordStrength}
                    password={props.values.password}
                    validPassword={setPasswordIsStrong}
                  >
                    <FormControl
                      id="password"
                      name="password"
                      type={props.values.showPassword ? 'text' : 'password'}
                      onChange={props.handleChange}
                      onFocus={() => {
                        setShowPasswordStrength(true);
                        passwordRef.current.removeAttribute('readOnly');
                      }}
                      onBlur={() => {
                        setShowPasswordStrength(false);
                        passwordRef.current.setAttribute('readOnly', '');
                      }}
                      value={props.values.password}
                      invalid={props.errors.password && props.touched.password}
                      placeholder="Insira sua senha"
                      ref={passwordRef}
                      autoComplete="off"
                      readOnly
                    />
                  </PasswordStrength>
                  <FormField>
                    <Field
                      type="checkbox"
                      name="showPassword"
                    />
                    Mostrar senha
                  </FormField>
                  <ErrorMessage name="password">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>

                <FormRow>
                  <FormLabel htmlFor="confirmPassword">*Confirme sua senha</FormLabel>
                  <FormControl
                    id="confirmPassword"
                    name="confirmPassword"
                    type={props.values.showConfirmPassword ? 'text' : 'password'}
                    onChange={props.handleChange}
                    onFocus={() => confirmPasswordRef.current.removeAttribute('readOnly')}
                    onBlur={() => confirmPasswordRef.current.setAttribute('readOnly', '')}
                    value={props.values.confirmPassword}
                    invalid={props.errors.confirmPassword && props.touched.confirmPassword}
                    placeholder="Insira sua senha"
                    ref={confirmPasswordRef}
                    autoComplete="off"
                    readOnly
                  />
                  <FormField>
                    <Field
                      type="checkbox"
                      name="showConfirmPassword"
                    />
                    Mostrar senha
                  </FormField>
                  <ErrorMessage name="confirmPassword">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to={link ? `/criar-uma-conta/${link}/aluno/email` : '/criar-uma-conta/aluno/email'}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.password || !props.values.confirmPassword || !passwordIsStrong}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'telefone'}>
        <Formik
          initialValues={{ cellPhone: formValues.cellPhone ?? '' }}
          validationSchema={cellPhoneSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Insira o número do seu telefone celular</h1>
                </div>

                <FormRow>
                  <FormLabel htmlFor="cellPhone">Celular com DDD</FormLabel>
                  <FormControl
                    id="cellPhone"
                    name="cellPhone"
                    type="tel"
                    value={props.values.cellPhone}
                    invalid={props.errors.cellPhone && props.touched.cellPhone}
                    placeholder="(00) 00000-0000"
                    ref={cellPhoneRef}
                  />
                  <ErrorMessage name="cellPhone">{msg => <FormFeedback invalid={true}>{msg}</FormFeedback>}</ErrorMessage>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to={link ? `/criar-uma-conta/${link}/aluno/senha` : '/criar-uma-conta/aluno/senha'}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={props.isSubmitting || !props.values.cellPhone}
                >
                  Próximo
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'termos'}>
        <Formik
          initialValues={{ terms: formValues.terms ?? true }}
          validationSchema={termsSchema}
          onSubmit={handleNext}
        >
          {props => (
            <Form>
              <div className={styles.body}>
                <div className={styles.textGroup}>
                  <h1 className={styles.title}>Termos de uso e política de privacidade</h1>
                </div>

                <FormRow>
                  <FormTerms />
                </FormRow>

                <FormRow>
                  <FormField round>
                    <Field
                      type="checkbox"
                      name="terms"
                    />
                    <span>
                      Ao clicar em “Criar conta”, você concorda com os{' '}
                      <a
                        href="/termos-de-uso"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        termos de uso
                      </a>{' '}
                      e{' '}
                      <a
                        href="/politica-de-privacidade"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        política de privacidade
                      </a>
                    </span>
                  </FormField>
                </FormRow>
              </div>

              <ButtonWrapper>
                <Button
                  modifiers={['outline']}
                  anchor
                  to={link ? `/criar-uma-conta/${link}/aluno/telefone` : '/criar-uma-conta/aluno/telefone'}
                  style={{ minWidth: 134 }}
                >
                  Voltar
                </Button>
                <Button
                  modifiers={['primary']}
                  type="submit"
                  style={{ minWidth: 134 }}
                  disabled={!props.values.terms}
                >
                  Criar minha conta
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </FormStep>

      <FormStep show={formStep === 'conta-criada'}>
        <div className={styles.body}>
          <div className={styles.textGroup}>
            <h1 className={styles.title}>Conta foi criada!</h1>
            <p className={styles.description}>Enviamos um email para você. Acesse-o e ative sua conta.</p>
          </div>
        </div>
      </FormStep>
    </LoginLayout>
  );
}
