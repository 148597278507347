import React from 'react';
import brain1 from './img/brain-1.svg';
import brain2 from './img/brain-2.svg';
import brain3 from './img/brain-3.svg';
import brain4 from './img/brain-4.svg';
import brain5 from './img/brain-5.svg';
import { useState, useEffect } from 'react';
import FlashcardModal from 'app/screens/StudyPlan/StudyBlock/FlashcardModal';
import { useClient } from 'jsonapi-react';
import FlashcardGuideModal from 'app/screens/StudyPlan/StudyBlock/FlashcardGuideModal';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

function Brain(props) {
  const { days } = props;

  const getImage = day => {
    if (day === 0) {
      return { src: brain5, size: 80 };
    } else if (day > 0 && day <= 1) {
      return { src: brain4, size: 68 };
    } else if (day > 1 && day <= 2) {
      return { src: brain3, size: 66 };
    } else if (day > 2 && day <= 4) {
      return { src: brain2, size: 50 };
    } else {
      return { src: brain1, size: 28 };
    }
  };

  return (
    <div className="review-card__img-wrapper">
      <img
        src={getImage(days).src}
        alt="cérebro"
        className="review-card__img"
        style={{ width: getImage(days).size }}
      />
    </div>
  );
}

export default function ReviewCard(props) {
  const [flashcardData, setFlashcardData] = useState([]);
  const [flashcardsAccepted, setFlashcardsAccepted] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [advice, setAdvice] = useState(false);
  const [done, setDone] = useState(false);
  const [days, setDays] = useState(0);
  const { t } = useTranslation();

  const client = useClient();

  const getReviewDays = async () => {
    const response = await client.fetch('user_flashcards/last_review');
    if (response.data?.message === 'No flashcards found') {
      setDays(prev => (prev = -1));
    } else {
      setDays(response?.data?.['days-last-review']);
    }
  };

  const fetchFlashCardData = async () => {
    try {
      const { data } = await client.mutate('/user_flashcards/latest', {}, { method: 'POST' });

      const flashcards = data?.flatMap(item =>
          item.flashcards.map(flash => ({
            ...flash,
            user_flashcard_id: item.id
          }))
      );

      const flashcardsAccepted = data?.flatMap(item => item['user-answer-flashcards']);
      setFlashcardsAccepted(flashcardsAccepted);

      const percentage = (flashcardsAccepted?.length / flashcards?.length) * 100;
      setPercentage(percentage.toFixed(2));

      setFlashcardData(flashcards);
    } catch (e) {
      console.error(e)
      toast.error(t('toast.errorStartFlashCard'));
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchFlashCardData();
    getReviewDays();
  }, [showModal]);

  return (
    <div className="review-card">
      <Brain days={days} />

      <div className="review-card__body">
        {days === -1 ? (
          <>
            <h3 className="review-card__title">Sem Dados</h3>
            <p className="review-card__text">para revisar</p>
          </>
        ) : (
          <>
            <h3 className="review-card__title">{days || '0'} dias</h3>
            <p className="review-card__text">sem revisar</p>
          </>
        )}
      </div>
      <button
        type="button"
        className="btn btn--full btn--primary"
        onClick={() => {
          setShowModal(true);
        }}
        disabled={!flashcardData}
      >
        Revisar agora
      </button>

      {flashcardData && percentage && (
        <FlashcardGuideModal
          show={showModal}
          onClose={() => handleCloseModal()}
          taxonomyName={'Teste'}
          flashcardData={flashcardData}
          flashcardsAccepted={flashcardsAccepted}
          onChangePercentage={setPercentage}
          percentage={percentage}
        />
      )}
    </div>
  );
}
