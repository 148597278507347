import React from 'react';
import styles from './LogosSeduc.module.scss';
import logoCanalEducacao from './img/logo-canal-educacao.svg';
import logoAceleraSeduc from './img/logo-acelera-seduc.svg';
import logoSeduc from './img/logo-seduc.svg';

export function LogosSeduc(props) {
  const { hasCustomLogos } = props;

  if (!hasCustomLogos) {
    return null;
  }

  return (
    <div className={styles.container}>
      <img
        src={logoCanalEducacao}
        alt="logo Canal Educação"
        className={styles.logoCanalEducacao}
      />

      <img
        src={logoAceleraSeduc}
        alt="logo Acelera SEDUC"
        className={styles.logoAceleraSeduc}
      />

      <img
        src={logoSeduc}
        alt="logo SEDUC"
        className={styles.logoSeduc}
      />
    </div>
  );
}
