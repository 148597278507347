import { PiBrain } from 'react-icons/pi';
import { FiCheckCircle, FiClipboard, FiCoffee, FiEdit, FiEdit3, FiHome, FiMap, FiMessageSquare, FiMonitor, FiVideo } from 'react-icons/fi';
import { VscGame } from 'react-icons/vsc';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import brain from './img/brain.svg';
import monitor from './img/monitor.svg';
import check from './img/check.svg';
import activity from './img/activity.svg';
import message from './img/message.svg';
import IconPerformance from '../Icons/IconPerformance';
import IconExams from '../Icons/IconExams';
import IconSimulated from '../Icons/IconSimulated';
import IconExercises from '../Icons/IconExercises';
import { useSession } from 'app/hooks/useSession';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import getPermission from 'app/utils/getPermission';
import DuelIcon from '../Icons/DuelIcon';

export function studentMenu() {
  const { session } = useSession();
  const { abilities } = usePlatformAbilities();

  return [
    {
      id: 1,
      route: '/',
      icon: <FiHome className="menu__icon" />,
      label: 'Home'
    },
    {
      id: 2,
      icon: <PiBrain className="menu__icon" />,
      submenu: {
        img: brain,
        title: 'Aprendizado Dinâmico',
        subtitle: 'Aprendizagem guiada pela T.I.A. - nossa Tecnologia de Inteligência Artificial',
        nav: [
          {
            route: '/guia-de-estudos',
            icon: <FiMap className="submenu__icon" />,
            title: 'Guia de estudos',
            description: 'Sua trilha de aprendizagem personalizada',
            visible: abilities?.['plano-de-estudo']
          },
          {
            route: '/desafio-da-semana/desafios',
            icon: (
              <VscGame
                className="submenu__icon"
                strokeWidth={0.6}
              />
            ),
            title: 'Desafio da semana',
            description: 'Vença disputas de conhecimento e acumule pontos',
            visible: abilities?.['desafio-da-semana']
          },
          {
            route: '/duelos',
            icon: <DuelIcon className="submenu__icon" />,
            title: 'Duelos',
            description: 'Participe de duelos e teste seus conhecimentos',
            visible: abilities?.duelos
          },
          {
            route: `/usuario/metricas/${session?.user?.id}`,
            icon: <IconPerformance className="submenu__icon" />,
            title: 'Desempenho',
            description: 'Acompanhe sua performance',
            visible: abilities?.desempenho
          }
        ]
      }
    },
    {
      id: 3,
      icon: <FiMonitor className="menu__icon" />,
      submenu: {
        img: monitor,
        title: 'Cursos e Aulas',
        subtitle: 'Uma biblioteca completa de recursos educacionais',
        nav: [
          {
            route: '/curso',
            icon: <FiMonitor className="submenu__icon" />,
            title: 'Cursos',
            description: 'Assista a aulas e acesse materiais complementares',
            visible: getPermission('Visualizar lista de módulos e seus conteúdos', 'Cursos') && abilities?.cursos
          },
          {
            route: '/aulas-ao-vivo',
            icon: <FiVideo className="submenu__icon" />,
            title: 'Aulas ao vivo',
            description: 'Participe de sessões interativas em tempo real',
            visible: abilities?.['aulas-ao-vivo'] && getPermission('Visualizar aulas ao vivo', 'Aulas ao vivo')
          }
        ]
      }
    },
    {
      id: 4,
      icon: <FiCheckCircle className="menu__icon" />,
      submenu: {
        img: check,
        title: 'Avaliações e Questões',
        subtitle: 'Coloque à prova o seu conhecimento',
        nav: [
          {
            route: '/avaliacoes',
            icon: <IconExams className="submenu__icon" />,
            title: 'Provas',
            description: 'Teste seu conhecimento',
            visible: abilities?.['avaliacoes-simulados'] || abilities?.['avaliacoes-provas']
          },
          {
            route: '/simulados',
            icon: <IconSimulated className="submenu__icon" />,
            title: 'Simulados',
            description: 'Simule exames reais',
            visible: abilities?.['avaliacoes-simulados'] || abilities?.['avaliacoes-provas']
          },
          {
            route: '/questions/apostilas',
            icon: <IconExercises className="submenu__icon" />,
            title: 'Exercícios',
            description: 'Responda perguntas e listas de exercícios',
            visible: abilities?.['avaliacoes-apostilas']
          }
        ]
      }
    },
    {
      id: 5,
      icon: <FiEdit3 className="menu__icon" />,
      submenu: {
        img: activity,
        title: 'Atividades',
        subtitle: 'Realize tarefas e acesse materiais extras',
        nav: [
          {
            route: '/atividade-complementar',
            icon: <BsFileEarmarkCheck className="submenu__icon" />,
            title: 'Atividade Complementar',
            description: 'Atividade de estudo individual',
            visible: getPermission('Visualizar lista de atividades', 'Arquivos e atividades complementares') && abilities?.['cursos-ativ-complementar']
          },
          {
            route: '/redacao',
            icon: <FiEdit className="submenu__icon" />,
            title: 'Redação',
            description: 'Desenvolva suas habilidades de escrita',
            visible: abilities?.redacao
          },
          {
            route: '/projetos',
            icon: <FiClipboard className="submenu__icon" />,
            title: 'Projetos',
            description: 'Realize trabalhos colaborativos e receba avaliações',
            visible: getPermission('Visualizar lista de projetos', 'Projetos') && abilities?.projetos
          }
        ]
      }
    },
    {
      id: 6,
      icon: <FiMessageSquare className="menu__icon" />,
      submenu: {
        img: message,
        title: 'Comunidade',
        subtitle: 'Aqui todos se reúnem para partilhar e aprender',
        nav: [
          {
            route: '/auditorio',
            icon: <FiMessageSquare className="submenu__icon" />,
            title: 'Monitoria',
            description: 'Tire dúvidas em tempo real',
            visible: abilities?.auditorio && getPermission('Visualizar monitorias', 'Monitorias')
          },
          {
            route: '/feed',
            icon: <FiCoffee className="submenu__icon" />,
            title: 'Fórum',
            description: 'Troque experiências com outros alunos e professores',
            visible: getPermission('Visualizar postagens e comentários', 'Fórum') && abilities?.feed
          }
        ]
      }
    }
  ];
}
