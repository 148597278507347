import BreadCrumbs from 'app/components/BreadCrumbs';
import EmptyState from 'app/components/EmptyState';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import DuelCard from './DuelCard';
import Requests from './Requests';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import { WeeklyChallengeProvider } from 'app/providers/WeeklyChallenge';
import NewDuelModal from './NewDuelModal';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import PlayerModal from './Player';
import Loader from 'app/components/loader';

export default function Duels(props) {
  return (
    <WeeklyChallengeProvider>
      <DuelsContent {...props} />
    </WeeklyChallengeProvider>
  );
}

function DuelsContent(props) {
  const { uri } = props;
  const [newDuelModal, setNewDuelModal] = useState(false);
  const [openPlayerModal, setOpenPlayerModal] = useState(false);
  const [duel, setDuel] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);
  const [duels, setDuels] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);
  const [loading, setLoading] = useState();
  const [loadingReceivedRequests, setLoadingReceivedRequests] = useState();
  const [loadingSentRequests, setLoadingSentRequests] = useState();
  const [winner, setWinner] = useState();
  const [activeReceivedTab, setActiveReceivedTab] = useState('active');

  const client = useClient();

  const { setCurrentChallenge, setCurrentStep, setCurrentRound, setRightAnswers } = useWeeklyChallenge();

  const filters = useFormik({
    initialValues: {
      topic: 'blank',
      status: 'ongoing'
    }
  });

  const getTaxonomies = async () => {
    try {
      const data = await client.fetch('/duels/taxonomies_for_creation');
      setTaxonomies(data);
    } catch (e) {
      console.error(e)
      toast.error(t('toast.errorGetTaxonomy'));
    }
  };

  const getSentRequests = async () => {
    setLoadingSentRequests(true);
    const { data, error } = await client.fetch('/duels/sent');
    if (error) {
      toast.error(t('toast.errorGetDuelsSentRequests'));
    } else {
      setSentRequests(data);
    }
    setLoadingSentRequests(false);
  };

  const getReceivedRequests = async () => {
    setLoadingReceivedRequests(true);
    const { data, error } = await client.fetch(`/duels/received?status=${activeReceivedTab}`);
    if (error) {
      toast.error(t('toast.errorGetDuelsReceivedRequests'));
    } else {
      setReceivedRequests(data);
    }
    setLoadingReceivedRequests(false);
  };

  const getDuels = async () => {
    let url = `/duels?status=${filters.values.status}`;
    if (filters.values.topic !== 'blank') {
      url;
    }

    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetDuels'));
    } else {
      setDuels(data);
    }
  };

  useEffect(() => {
    getReceivedRequests();
  }, [activeReceivedTab]);

  const handleStart = async duel => {
    setCurrentChallenge(duel);
    setCurrentRound(duel?.['rounds-completed-by-me'] + 1);
    setOpenPlayerModal(true);
    setDuel(duel);
    setRightAnswers(duel?.['rounds-won-by-me']);
  };

  const getWinner = async id => {
    const { data, error } = await client.fetch(`/duels/${id}/winner`);

    if (error) {
      toast.error('Erro ao buscar vencedor');
    } else {
      setWinner(data);
    }
  };

  const showResults = duel => {
    setCurrentChallenge(duel);
    getWinner(duel?.id);
    setOpenPlayerModal(true);
    setCurrentStep(3);
  };

  useEffect(() => {
    setLoading(true);
    Promise.allSettled([getTaxonomies(), getDuels(), getSentRequests(), getReceivedRequests()]).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getDuels();
  }, [filters.values]);

  const updateDuelsAndRequests = async () => {
    await Promise.all([getDuels(), getReceivedRequests()]);
  };

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: uri,
        name: 'Duelos',
        isActive: true
      }
    ]
  };

  const filteredDuels = duels.filter(duel => filters.values.topic === 'blank' || duel?.['parent-taxonomy']?.id === Number(filters.values.topic));

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />
      <div
        className="filter-bar"
        style={{ border: 'none' }}
      >
        <div className="filter-bar__inner">
          <FilterSelectionBox
            blankLabel={t('filter.blankLabelAll2')}
            label={t('filter.selectTopic')}
            value={filters.values.topic}
            onChange={e => filters.setFieldValue('topic', e.target.value)}
            options={taxonomies}
          />
          <div className={styles['status-filter']}>
            <input
              type="radio"
              name="status"
              id="ongoing"
              onClick={() => filters.setFieldValue('status', 'ongoing')}
              checked={filters.values.status === 'ongoing'}
            />
            <label htmlFor="ongoing">Em andamento</label>
            <input
              type="radio"
              name="status"
              id="expired"
              onClick={() => filters.setFieldValue('status', 'expired')}
              checked={filters.values.status === 'expired'}
            />
            <label htmlFor="expired">Expirados</label>
            <input
              type="radio"
              name="status"
              id="completed"
              onClick={() => filters.setFieldValue('status', 'completed')}
              checked={filters.values.status === 'completed'}
            />
            <label htmlFor="completed">Finalizados</label>
          </div>
        </div>
        <button
          className="btn btn--primary btn--wide"
          onClick={() => setNewDuelModal(true)}
        >
          Novo duelo
        </button>
      </div>

      {loading && <Loader />}

      {!loading && (
        <div className={styles['duels-page']}>
          {filteredDuels.length > 0 && (
            <div className={`${styles['duels-page__main']}`}>
              {filteredDuels.map(duel => (
                <DuelCard
                  handleStart={handleStart}
                  showResults={showResults}
                  duel={duel}
                  status={filters?.values?.status}
                />
              ))}
            </div>
          )}
          {filteredDuels.length === 0 && (
            <div className={styles['duels-page__empty']}>
              <EmptyState
                type="select"
                title="Crie um duelo"
                text="Inicie uma novo duelo e convide um oponente para participar"
              />
            </div>
          )}
          <aside className={styles['duels-page__aside']}>
            <Requests
              type="received"
              data={receivedRequests}
              setActiveTab={setActiveReceivedTab}
              activeTab={activeReceivedTab}
              updateDuelsAndRequests={updateDuelsAndRequests}
              loadingReceived={loadingReceivedRequests}
            />
            <Requests
              type="sent"
              data={sentRequests}
              loadingSent={loadingSentRequests}
            />
          </aside>
        </div>
      )}

      <NewDuelModal
        open={newDuelModal}
        setOpen={setNewDuelModal}
        taxonomies={taxonomies}
        getSentRequests={getSentRequests}
      />

      <PlayerModal
        setOpenPlayerModal={setOpenPlayerModal}
        setNewDuelModal={setNewDuelModal}
        duel={duel}
        openPlayerModal={openPlayerModal}
        winner={winner}
        getWinner={getWinner}
        setWinner={setWinner}
      />
    </main>
  );
}
