import { statusBadgeColor, statusBadgeText } from 'app/screens/Essay/utils/BadgeEssay';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import styles from './AccordionItemModal.module.scss';
import { sassTrue } from 'sass';
import { SimpleModal } from 'app/components/Modal';
import CompetenciesModal from './CompetencesModal';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function AccordionItemModal({ essay }) {
  const orderedEssays = essay?.essay_students?.sort((a, b) => b.submission_order - a.submission_order);
  const [active, setActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const accordionContent = useRef(null);
  const [selectedSent, setSelectedSent] = useState(null);
  const [sentDetails, setSentDetails] = useState(null);

  const client = useClient();

  const getSentDetails = async () => {
    try {
      const { data, error } = await client.fetch(`/reports/essays/essay_students/${selectedSent.id}`);
      if (error) {
        toast.error('Erro ao buscar detalhes da competência');
      } else {
        setOpenModal(true);
        setSentDetails(data);
      }
    } catch (error) {
      console.error('Erro ao buscar detalhes da competência');
    }
  };

  useEffect(() => {
    if (selectedSent) {
      getSentDetails();
    }
  }, [selectedSent]);

  const renderTableRow = (studentEssay, i) => (
    <tr
      className="round-dg__row"
      key={i}
    >
      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row u-pl-0">
        <div className="round-dg__user">
          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={essay?.topic}
            >
              {essay?.topic}
            </h3>
          </div>
        </div>
      </th>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: 54 }}
      >
        {studentEssay.submission_order}º
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: 90 }}
      >
        {studentEssay['created-at']}
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: 152 }}
      >
        <span
          title={statusBadgeText?.[studentEssay.status]}
          className={`badge badge--tiny ${statusBadgeColor?.[studentEssay.status]}`}
        >
          {statusBadgeText?.[studentEssay.status]}
        </span>
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: 294 }}
      >
        <div
          className={styles.noteAndCompetence}
          style={{ paddingRight: 12 }}
        >
          <span className={styles.note}>{studentEssay.grade}</span>{' '}
          <span
            className={styles.competence}
            title={studentEssay.competencies?.map(competency => competency.slug.toUpperCase() + ': ' + competency.score)?.join('   ') || '--'}
          >
            {studentEssay.competencies?.map(competency => competency.slug.toUpperCase() + ': ' + competency.score)?.join('   ') || '--'}
          </span>
          <span
            style={{ color: 'var(--color-primary)', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={e => {
              e.stopPropagation();
              setSelectedSent(studentEssay);
            }}
          >
            mais detalhes
          </span>
        </div>
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: 120 }}
      >
        <span
          className="u-text-truncate"
          title={studentEssay.corrector || '--'}
          style={{ maxWidth: 90 }}
        >
          {studentEssay.corrector || '--'}
        </span>
      </td>
    </tr>
  );

  return (
    <>
      <div className={`accordion__item ${active ? 'active' : ''}`}>
        <div
          className="accordion__header"
          style={{ gap: 0 }}
          onClick={() => setActive(prev => !prev)}
        >
          <div
            className="round-dg-wrapper"
            style={{ width: '100%' }}
          >
            <table style={{ width: '100%', minWidth: 904 }}>
              <tbody>{orderedEssays?.length > 0 && renderTableRow(orderedEssays[0], 0)}</tbody>
            </table>
          </div>

          <button className="card__icon-wrapper card__icon-wrapper--sm u-ml-auto">
            <FiChevronDown className="card__icon" />
          </button>
        </div>

        <div
          className="accordion__collapse"
          style={{
            height: active ? `${accordionContent.current?.scrollHeight}px` : '0'
          }}
        >
          <div
            className="accordion__content"
            ref={accordionContent}
          >
            <div
              className="round-dg-wrapper"
              style={{ width: '100%' }}
            >
              <table className={styles.responsiveTable}>
                <tbody>{orderedEssays?.slice(1)?.map((studentEssay, i) => renderTableRow(studentEssay, i))}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SimpleModal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelectedSent(null);
        }}
        contentClassName="simple-modal__content--full"
        hideCloseButton
      >
        <CompetenciesModal sentDetails={sentDetails} />
      </SimpleModal>
    </>
  );
}
