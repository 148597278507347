export const statusBadgeColor = {
  accepting_proposal: 'badge--attention',
  close_for_submissions: 'badge--neutral',
  processing: 'badge--warning',
  unreviewed: 'badge--success',
  reviewed: 'badge--success',
  annulled: 'badge--danger',
  annulled_by_teacher: 'badge--danger ',
  minimum_length_not_satisfied: 'badge--danger',
  failed_to_process: 'badge--danger',
  reviewing: 'badge--warning',
  not_sent: 'badge--neutral',
  ocr_review: 'badge--warning',
  sending_by_teacher: 'badge--warning'
};

export const statusBadgeText = {
  accepting_proposal: 'Aceitando respostas',
  close_for_submissions: 'Prazo encerrado',
  processing: 'Em processamento',
  unreviewed: 'Correção por IA',
  reviewed: 'Correção por Professor',
  annulled: 'Anulada pela IA',
  annulled_by_teacher: 'Anulada pelo Professor',
  minimum_length_not_satisfied: 'Arquivo inválido',
  failed_to_process: 'Falha ao processar',
  reviewing: 'Em revisão',
  not_sent: 'Aluno não enviou',
  ocr_review: 'Aguardando envio do texto',
  sending_by_teacher: 'Professor enviando'
};
