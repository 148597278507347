import React, { useEffect } from 'react';
import 'moment/locale/pt';
import Notes from './Notes';
import Notifications from './Notifications';
import getPermission from '../utils/getPermission';
import DropdownMessages from './DropdownMessages';
import { useTranslation } from 'react-i18next';
import Search from './Search';
import EnvironmentSelector from './EnvironmentSelector';

function MainNav(props) {
  const { hasCustomLogos } = props;

  const { t, i18n } = useTranslation();

  const appName = process.env.REACT_APP_NAME;

  let supportUrl = '';

  switch (appName) {
    case 'maiseducacao':
      supportUrl = 'https://suportemaisformacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'canaleducacao':
      supportUrl = 'https://suportecanaleducacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'egepi':
      supportUrl = 'https://escoladegoverno.zendesk.com/hc/pt-br/requests/new';
      break;
    default:
      supportUrl = 'https://wa.me/5511978432586';
  }

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);
  }, []);

  const showNotes = getPermission('Criar anotações', 'Anotações') || getPermission('Editar anotações', 'Anotações') || getPermission('Excluir anotações', 'Anotações') || getPermission('Visualizar anotações', 'Anotações');

  return (
    <div className={hasCustomLogos ? 'header header--has-custom-logos' : 'header'}>
      <div className="header__container">
        <div className="header__actions">
          <EnvironmentSelector />
          <Search />
          <DropdownMessages />
          <Notifications />
        </div>

        {showNotes && <Notes hasCustomLogos={hasCustomLogos} />}
      </div>
    </div>
  );
}

export default MainNav;
