import React, { useEffect, useState } from 'react';
import { FiCalendar, FiCheck, FiChevronDown, FiEdit3 } from 'react-icons/fi';
import { Link } from '@reach/router';
import { t } from 'i18next';
import Dropdown from 'app/components/Dropdown';
import getPermission from 'app/utils/getPermission';
import moment from 'moment';
import { statusBadgeColor, statusBadgeText } from './utils/BadgeEssay';
import tag from './img/tag_novo.png';
import styles from './styles.module.scss';
import { WarningModal } from 'app/components/Modal/WarningModal';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function EssayCard(props) {
  const { essay, isAdmin, deleteEssay } = props;
  const [unpublishWarning, setUnpublishWarning] = useState(false);
  const [publishWarning, setPublishWarning] = useState(false);
  const [loading, setLoading] = useState(false);

  const client = useClient();

  const accepting_proposal = essay?.status === 'accepting_proposal';
  const reviewing = essay?.status === 'reviewing';

  const isCorrected = essay?.['essay-student']?.grade;
  const grade = essay?.['essay-student']?.grade ?? 0;

  let badgeColor = '';
  let badgeText = '';

  if (accepting_proposal) {
    badgeColor = 'badge--attention';
    badgeText = statusBadgeText[essay?.status];
  } else {
    badgeColor = statusBadgeColor[essay?.status] ?? 'badge--success';
    badgeText = statusBadgeText[essay?.status];
  }

  if (reviewing) {
    badgeColor = 'badge--success';
    badgeText = statusBadgeText.unreviewed;
  } else {
    badgeColor = statusBadgeColor[essay?.status] ?? 'badge--success';
    badgeText = statusBadgeText[essay?.status];
  }

  const essayCreatedAtlessThan15Days = essay => moment().diff(essay?.['created-at'], 'days') <= 15;

  const updateEssayPublicity = async () => {
    setLoading(true);
    const { error } = await client.mutate(
      `essays/${essay.id}/toggle_visibility`,
      {},
      {
        method: 'PATCH'
      }
    );
    if (!error) {
      toast.success(!essay.visible ? 'Publicado com sucesso!' : 'Despublicado com sucesso!');
    } else {
      toast.error('Erro ao publicar redação');
    }
    setLoading(false);
    essay.visible = !essay.visible;
    setUnpublishWarning(false);
    setPublishWarning(false);
  };

  const handlePublishEssay = () => {
    if (!essay.visible) {
      setUnpublishWarning(true);
    } else if (essay.visible) {
      setPublishWarning(true);
    } else {
      updateEssayPublicity();
    }
  };

  return (
    <>
      <div
        className="card"
        style={{ justifyContent: 'space-between' }}
      >
        {essayCreatedAtlessThan15Days(essay) && (
          <img
            src={tag}
            style={{ width: '60px', position: 'absolute', top: '-20px' }}
          />
        )}
        <div className={styles['essay-card']}>
          <span
            className="card__icon-wrapper card__icon-wrapper--sm"
            style={{ alignSelf: 'flex-start' }}
          >
            <FiEdit3 className="card__icon card__icon--md" />
          </span>

          <div>
            <div className="card__header">
              <div className="card__inner-header u-mb-2">
                <h2
                  className="card__title"
                  title={essay?.topic}
                >
                  {essay?.topic.length > 50 ? essay?.topic.slice(0, 50) + '...' : essay?.topic}
                </h2>
              </div>

              <span
                title={badgeText}
                className={`badge badge--tiny ${badgeColor}`}
              >
                {badgeText}
              </span>
            </div>
            {getPermission('Editar redação', 'Redação') && (
              <div
                className="form__check form__switch"
                onClick={e => e.stopPropagation()}
              >
                <input
                  className="form__check-input"
                  id={`publish-${essay.id}`}
                  name={`publish-${essay.id}`}
                  type="checkbox"
                  checked={essay.visible}
                  onClick={handlePublishEssay}
                />

                <label htmlFor={`publish-${essay.id}`}>{t('lms.publish')}</label>
              </div>
            )}

            <div className="card__body">
              {moment(essay?.['publish-at']).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') && (
                <div className={styles['essay-card-info']}>
                  <FiCheck />
                  <span className="u-w-100">{'Publicação em '}</span>
                  {moment(essay?.['publish-at']).format('DD/MM/YYYY')}
                </div>
              )}
              {(accepting_proposal || essay?.status === 'close_for_submissions' || essay?.status === 'ocr_review') && (
                <div className={styles['essay-card-info']}>
                  <FiCalendar />
                  <span className="u-w-100">{'Até '}</span>
                  {moment(essay?.['delivery-date']).format('DD/MM/YYYY')}
                </div>
              )}

              {['processing', 'annulled', 'annulled_by_teacher'].includes(essay?.status) && (
                <div style={{ display: 'inline-flex', gap: '24px' }}>
                  <div className={styles['essay-card-info']}>
                    <FiCalendar />
                    <span className="u-w-100">{'Em '}</span>
                    {moment(essay?.['essay-student']?.['delivery-date']).format('DD/MM/YYYY')}
                  </div>
                  <p className={styles['essay-card-info']}>
                    <span className="u-w-100">{essay?.['essay-student']?.['submission_order']}° envio</span>
                  </p>
                </div>
              )}

              {['reviewed', 'unreviewed', 'reviewing'].includes(essay?.status) && (
                <div style={{ display: 'inline-flex', gap: '24px' }}>
                  <p className={styles['essay-card-info']}>
                    <FiCheck />
                    <span className="u-w-100">{'Pontuação '}</span>
                    {grade}
                  </p>
                  <p className={styles['essay-card-info']}>
                    <span className="u-w-100">{essay?.['essay-student']?.['submission_order']}° envio</span>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card__footer">
          {isAdmin ? (
            <div
              className="btn-group btn-group--with-dropdown"
              role="group"
              aria-label="Ações"
            >
              <Link
                to={`/redacao/acompanhamento/${essay?.id}`}
                className="btn btn--outline btn--small"
              >
                {t('button.access')}
              </Link>
              {getPermission('Excluir redação', 'Redação') || getPermission('Editar redação', 'Redação') ? (
                <Dropdown className="btn--small btn btn--outline">
                  <Dropdown.Toggle>
                    <FiChevronDown />
                  </Dropdown.Toggle>

                  <Dropdown.Content>
                    {getPermission('Editar redação', 'Redação') && <Dropdown.Item to={`/redacao/editar/curso/${essay?.id}`}>{t('button.edit')}</Dropdown.Item>}
                    {getPermission('Excluir redação', 'Redação') && <Dropdown.Item onClick={() => deleteEssay(essay?.id)}>{t('button.delete')}</Dropdown.Item>}
                  </Dropdown.Content>
                </Dropdown>
              ) : null}
            </div>
          ) : essay?.status === 'reviewed' || essay.status === 'unreviewed' || essay.status === 'reviewing' ? (
            <Link
              to={`/redacao/consulta/${essay?.id}/${essay?.['essay-student']?.id}`}
              className="btn btn--outline btn--small"
            >
              Acessar
            </Link>
          ) : essay.status === 'ocr_review' ? (
            <Link
              to={`/redacao/enviar/${essay?.id}`}
              className="btn btn--outline btn--small"
            >
              Acessar
            </Link>
          ) : (
            <Link
              to={`/redacao/sobre/${essay?.id}`}
              className={`btn btn--outline btn--small ${essay?.status === 'Em correção' || (essay?.status === 'close_for_submissions' && 'disabled')}`}
            >
              Acessar
            </Link>
          )}
        </div>
      </div>
      <WarningModal
        isSubmitting={loading}
        show={unpublishWarning}
        titleFirstButton="Confirmo que quero publicar"
        titleSecondButton="Manter despublicado"
        onClose={() => setUnpublishWarning(false)}
        title={'Você tem certeza que deseja publicar a redação?'}
        description={'Ao publicar, esta redação ficará visível para os alunos.'}
        onSubmitFirstButton={() => updateEssayPublicity()}
        onSubmitSecondButton={() => setUnpublishWarning(false)}
      />

      <WarningModal
        isSubmitting={loading}
        show={publishWarning}
        titleFirstButton="Confirmo que quero despublicar"
        titleSecondButton="Manter publicada"
        onClose={() => setPublishWarning(false)}
        title={'Ao despublicar, esta redação ficará invisível para os alunos.'}
        description={'Você pode publicá-la novamente a qualquer momento. Deseja prosseguir com a despublicação?'}
        onSubmitFirstButton={() => updateEssayPublicity()}
        onSubmitSecondButton={() => setPublishWarning(false)}
      />
    </>
  );
}
