import React from 'react';
import styles from './Login.module.scss';
import { Link } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import Icon, { IconWrapper } from 'app/components/Icon/Icon';
import TIA from 'app/components/TIA';
import { getSupportUrl } from 'app/utils/getSupportUrl';

export default function LoginLayout(props) {
  const { children, back } = props;

  const appName = process.env.REACT_APP_NAME;
  const logo = require(`app/images/${appName}/horizontal-logo.svg`);

  let bannerImage;
  
  switch (appName) {
    case 'unisescoop': 
      bannerImage = require('app/images/unisescoop/login-bg.png');
      break;
    case 'canaleducacao':
      bannerImage = require('app/images/canaleducacao/login-bg.png')
      break;
    case 'maiseducacao':
      bannerImage = require('app/images/maiseducacao/login-bg.jpg');
      break;
    case 'encceja':
      bannerImage = require('app/images/encceja/login-bg.png');
      break;
    case 'egepi':
      bannerImage = require('app/images/egepi/login-bg.png');
      break;
    case 'pit':
      bannerImage = require('app/images/pit/login-bg.png');
      break;

    default:
      bannerImage = require('app/images/login-bg.jpg');
  }

  return (
    <div className={styles.login}>
      <TIA
        login
        message={
          <span>
            Precisa de ajuda?
            <br />
            <a
              href={getSupportUrl(appName)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Clique aqui
            </a>
          </span>
        }
      />

      <div className={styles.container}>
        <main className={styles.main}>
          <div className={styles.header}>
            {back && (
              <Link
                className={styles.back}
                to={back}
              >
                <IconWrapper>
                  <Icon>
                    <FiChevronLeft />
                  </Icon>
                </IconWrapper>
                Voltar
              </Link>
            )}

           <img
              src={logo}
              alt="Logos"
              className={styles.logo}
            />
          </div>

          {children}
        </main>
      </div>

      <figure className={styles.figure}>
        <img
          src={bannerImage}
          alt="imagem do banner"
          aria-hidden="true"
          className={styles.image}
        />
      </figure>
    </div>
  );
}
