import AWS from 'aws-sdk';
import { toast } from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

export const uploadFile = async (path, file) => {
  const S3_BUCKET = process.env.REACT_APP_AWS_FOG_DIRECTORY;
  const REGION = process.env.REACT_APP_AWS_REGION;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION
  });

  const filenameList = file.name.split('.');
  const key = uuid().replaceAll('-', '') + '.' + filenameList[filenameList.lastIndex];

  const params = {
    Bucket: S3_BUCKET,
    Key: path + key,
    Body: file
  };

  // var upload = s3
  //   .putObject(params)
  //   .on('httpUploadProgress', evt => {
  //     console.log('Uploading ' + parseInt((evt.loaded * 100) / evt.total) + '%');
  //   })
  //   .promise();

  // console.log(upload);
  // await upload.then((err, data) => {
  //   console.log(err);
  //   toast.success('Arquivo enviado com sucesso!');
  // });

  const upload = s3.upload(params).promise();
  return upload;
};

export const uploadFileFromPresignedUrl = async (url, file) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-amz-acl': 'public-read',
        'Content-Type': file.type
      }
    });

    if (!response.ok) {
      throw new Error('Erro ao enviar o arquivo.');
    }

    return url.split('?')[0];
  } catch (error) {
    toast.error('Erro ao enviar imagem da redação.');
    console.error(error);
    throw error;
  }
};
