import React from 'react';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { navigate } from '@reach/router';
import toast from 'react-hot-toast';
import StepCourse from './StepCourse';
import StepStatement from './StepStatement';
import StepTitle from './StepTitle';
import { useClient } from 'jsonapi-react';
import { useState } from 'react';
import { useEffect } from 'react';
import promiseRequest from 'app/utils/promiseToast';
import * as yup from 'yup';
import moment from 'moment';
import { get } from 'draft-js/lib/DefaultDraftBlockRenderMap';

export default function EssayContent(props) {
  const { step, steps, currentStep, setCurrentStep, essayId } = props;
  const [essay, setEssay] = useState();
  const client = useClient();
  const [deletedTexts, setDeletedTexts] = useState([]);
  const [classroomError, setClassroomError] = useState(false);
  const { promiseToast, isSubmitting } = promiseRequest();

  const getToday = () => moment().format('YYYY-MM-DD');

  const createEssayFormValidationSchema = yup.object().shape({
    topic: yup.string().required('Campo obrigatório'),
    statement: yup.string().required('Campo obrigatório'),
    delivery_date: yup
      .date()
      .required('Campo obrigatório')
      .min(getToday(), 'A data de entrega deve ser igual ou posterior à data atual')
      .when('publish_at', (publish_at, schema) => (publish_at ? schema.min(publish_at, 'A data de entrega deve ser igual ou superior à data de publicação') : schema)),
    publish_at: yup.date().required('Campo obrigatório').min(getToday(), 'A data de publicação deve ser igual ou posterior à data atual'),
    classroom_ids: yup.array().of(yup.array().min(1, 'Campo obrigatório').required('Campo obrigatório').of(yup.string().required('Campo obrigatório'))),
    essay_motivator_texts_attributes: yup.array().of(
      yup.object().shape({
        body: yup.string().required('Campo obrigatório')
      })
    )
  });

  const createEssayForm = useFormik({
    initialValues: {
      topic: '',
      statement: '',
      delivery_date: '',
      publish_at: '',
      classroom_ids: [[]],
      essay_motivator_texts_attributes: [{ body: '' }],
      course_ids: [],
      allow_multiple_submissions: false
    },

    validationSchema: createEssayFormValidationSchema,
    onSubmit: () => handleSubmit
  });

  useEffect(() => {
    if (createEssayForm.values.classroom_ids[0]?.length > 0) {
      setClassroomError(false);
    }
  }, [createEssayForm.values.classroom_ids[0]?.length]);

  const handleSubmit = e => {
    e.preventDefault();
    switch (step) {
      case 'curso':
        createEssayForm.setFieldTouched('classroom_ids', true);
        if (!essayId) {
          if (createEssayForm.values.classroom_ids[0]?.length > 0) {
            navigate(`/redacao/criar/enunciado`);
            setCurrentStep(1);
            break;
          } else {
            setClassroomError(true);
            break;
          }
        } else {
          if (createEssayForm.values.classroom_ids[0]?.length > 0) {
            navigate(`/redacao/editar/enunciado/${essayId}`);
            setCurrentStep(1);
            break;
          } else {
            setClassroomError(true);
            break;
          }
        }

      case 'enunciado':
        createEssayForm.setFieldTouched('essay_motivator_texts_attributes', true);
        createEssayForm.setFieldTouched('statement', true);
        createEssayForm.setFieldTouched('topic', true);
        if (!essayId) {
          if (!createEssayForm.errors.essay_motivator_texts_attributes && !createEssayForm.errors.statement && !createEssayForm.errors.topic) {
            navigate(`/redacao/criar/titulo`);
            setCurrentStep(2);
          }
          break;
        } else {
          if (!createEssayForm.errors.essay_motivator_texts_attributes && !createEssayForm.errors.statement && !createEssayForm.errors.topic) {
            navigate(`/redacao/editar/titulo/${essayId}`);
            setCurrentStep(2);
          }
          break;
        }

      case 'titulo':
        createEssayForm.setFieldTouched('delivery_date', true);
        createEssayForm.setFieldTouched('publish_at', true);
        if (!createEssayForm.errors.title && !createEssayForm.errors.publish_at && !createEssayForm.errors.delivery_date) {
          createEssay();
        }
        break;
    }
  };

  const getEssay = async () => {
    const { data, error } = await client.fetch(['essays', essayId]);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setEssay(data);
      createEssayForm.setValues({
        topic: data.topic,
        statement: data.statement,
        delivery_date: data['delivery-date'],
        publish_at: data['publish-at'],
        classroom_ids: [],
        essay_motivator_texts_attributes: data['essay-motivator-texts'],
        course_ids: []
      });
    }
  };

  useEffect(() => {
    if (essayId) {
      getEssay();
    }
  }, [essayId]);

  const createEssay = async () => {
    const requestParams = { ...createEssayForm.values };
    requestParams.classroom_ids = requestParams.classroom_ids?.flat()?.map(item => item.value);
    requestParams.essay_motivator_texts_attributes = [...requestParams.essay_motivator_texts_attributes, ...deletedTexts];

    delete requestParams.course_ids;
    const url = essayId ? ['essays', essayId] : 'essays';

    promiseToast({
      url,
      request: requestParams,
      successText: essayId ? 'Redação editada com sucesso' : 'Redação criada com sucesso',
      errorText: essayId ? 'Erro ao editar redação' : 'Erro ao criar redação'
    }).then(data => navigate(`/redacao/acompanhamento/${data.id}`));
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form form--step-by-step"
          method="post"
          onSubmit={handleSubmit}
        >
          <StepCourse
            classroomError={classroomError}
            essay={essay}
            essayId={essayId}
            step={step}
            form={createEssayForm}
          />
          <StepStatement
            setDeletedTexts={setDeletedTexts}
            step={step}
            form={createEssayForm}
          />
          <StepTitle
            step={step}
            form={createEssayForm}
          />

          <div style={btnsStyles}>
            {step !== 'curso' && (
              <button
                type="button"
                className="btn btn--outline btn--wide"
                onClick={() => {
                  navigate(!essayId ? `/redacao/criar/${steps?.[currentStep - 1]?.route}` : `/redacao/editar/${steps?.[currentStep - 1]?.route}/${essayId}`);
                  setCurrentStep(currentStep - 1);
                }}
              >
                {t('exams.previous')}
              </button>
            )}

            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {step !== 'titulo' ? t('button.next') : t('button.finish')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
