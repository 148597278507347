import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Link, navigate } from '@reach/router';
import { FiCamera, FiChevronLeft, FiTrash2 } from 'react-icons/fi';
import EssaySendTips from './EssaySendTips';
import promiseRequest from 'app/utils/promiseToast';
import BreadCrumbs from 'app/components/BreadCrumbs';
import imgTia from 'app/components/TIA/img/tia-avatar.png';
import Avatar from 'app/components/Avatar';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './styles.module.scss';
import { SimpleModal } from 'app/components/Modal';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { uploadFileFromPresignedUrl } from 'app/utils/aws-sdk';
import ActionCableConsumer from 'app/utils/actionCableConsumer';
import { useSession } from 'app/hooks/useSession';
import api from "../../../services/api";

export default function EssaySendContent({ essayId, studentId }) {
  const [imageLabel, setImageLabel] = useState();
  const [openLoaderModal, setOpenLoaderModal] = useState(false);
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [webhookNotification, setWebhookNotification] = useState(null);
  const textareaRef = useRef(null);
  const { promiseToast, isSubmitting } = promiseRequest();
  const appname = process.env.REACT_APP_NAME;
  const client = useClient();
  const [essayText, setEssayText] = useState('');
  const [essayStudentId, setEssayStudentId] = useState('');
  const [loading, setLoading] = useState(false);
  const [submissionsNumber, setSubmissionsNumber] = useState();
  const fileInputRef = useRef(null);
  const [sendingByTeacherId, setSendingByTeacherId] = useState(null);

  const { session } = useSession();

  const getEssayStatus = async () => {
    setLoading(true);
    const url = studentId ? `/essays/${essayId}?show_for_student_id=${studentId}` : `/essays/${essayId}`;
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao buscar status da redação');
    } else {
      const status = data['essay-student'].status;
      const essayStudentId = data['essay-student'].id;
      setSubmissionsNumber(data['essay-student']?.['submission_order']);
      setEssayStudentId(data['essay-student']?.id);

      if (status === 'ocr_review') {
        const { data, error } = await client.fetch(`/essays/${essayId}/essay_students/${essayStudentId}`);
        if (error) {
          toast.error('Erro ao buscar status da redação');
        } else {
          setEssayText(data?.body);
          setImageLabel(data?.['essay_image']?.url);
        }
      }
    }
    setLoading(false);
  };

  const transcribeEssay = async e => {
    const file = e.target.files[0];
    if (!file) return; // No file selected

    // Start loader and record teacher id
    setOpenLoaderModal(true);
    setSendingByTeacherId(session?.user?.id);

    // Build form data with conditional user_id if studentId exists
    const formData = {
      filename: file.name,
      content_type: file.type,
      ...(studentId && { user_id: studentId })
    };

    try {
      // Step 1: Create essay student record
      const postRes = await client.mutate(`/essays/${essayId}/essay_students`, formData);
      const uploadUrl = decodeURI(postRes.data['upload-url']);
      const essayStudentId = postRes.data.id;

      // Step 2: Upload file using the pre-signed URL
      const uploadedFileUrl = await uploadFileFromPresignedUrl(uploadUrl, file);
      const fileName = uploadedFileUrl.split('/').pop();

      // Step 3: Update essay student record with the uploaded image name
      const patchRes = await client.mutate(
          `/essays/${essayId}/essay_students/${essayStudentId}`,
          { essay_image_name: fileName },
          { method: 'PATCH' }
      );

      if (patchRes.error) {
        toast.error(patchRes.error.message);
        return
      }

      const patchData = patchRes.data;

      // Validate transcribed text and update UI state accordingly
      if (!patchData.body || patchData.body.length < 100) {
        setOpenErrorModal(true);
        setEssayText(null);
      } else {
        setEssayText(patchData.body);
        setEssayStudentId(patchData.id);
        setImageLabel(patchData.essay_image.url);
      }
    } catch (err) {
        toast.error("Houve um problema ao enviar a imagem da redação.");
        console.log(err);
    } finally {
      setOpenLoaderModal(false);
    }
  };

  const sendEssay = async () => {
    promiseToast({
      url: `/essays/${essayId}/essay_students/${essayStudentId}/correction`,
      request: { essay_student_content_attributes: { body: essayText } },
      successText: 'Redação enviada com sucesso',
      errorText: 'Erro ao enviar redação',
      onSuccess: () => navigate(`/redacao/${studentId ? 'acompanhamento' : 'sobre'}/${essayId}`)
    });
  };

  useEffect(() => {
    getEssayStatus();
  }, []);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.value = essayText;
    }
  }, [essayText]);

  const handleReceivedNotification = data => {
    setWebhookNotification(data);
  };

  useEffect(() => {
    if (!webhookNotification) return;

    if (webhookNotification?.id && sendingByTeacherId !== session?.user?.id && parseInt(studentId) === webhookNotification?.user_id && webhookNotification?.status === 'sending_by_teacher') {
      toast.error('Outro professor já enviou essa redação.');
      navigate(`/redacao/acompanhamento/${essayId}`);
    }
  }, [webhookNotification]);

  return (
    <div>
      {essayId && studentId && (
        <ActionCableConsumer
          channel="EssayStudentChannel"
          setObjectChannel={handleReceivedNotification}
          channelKey={'essay_id'}
          channelId={essayId}
        />
      )}

      <div className="main-sidebar">
        <div className="main-sidebar__container ">
          <div className="filter-bar">
            <Link
              to={'/redacao'}
              className="filter-bar__back"
            >
              <div className="card__icon-wrapper">
                <FiChevronLeft />
              </div>
              <span className="filter-bar__back-container">
                <span className="filter-bar__title">Enviando a redação</span>
              </span>
            </Link>
          </div>
          <div className="tia">
            <Avatar
              className="avatar--md"
              src={imgTia}
              alt="T.I.A"
              data-tooltip-id="tia"
              data-tooltip-place="top-start"
              data-tooltip-variant="light"
            />
          </div>
          <div className={styles['text-balloon']}>
            <span className={styles['text-balloon-title']}>Enviando a redação</span>
            <p>Escreva seu texto em nossa folha de redação (baixe e imprima no botão abaixo) ou utilize um folha pautada no tamanho A4. Depois envie a foto da redação clicando no botão ao lado “Enviar foto da redação”.</p>
            <a
              href={appname === 'canaleducacao' ? '/folha_de_redacao_laboratorio.pdf' : '/redação.pdf'}
              download={appname === 'canaleducacao' ? '/folha_de_redacao_laboratorio.pdf' : '/redação.pdf'}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--outline btn--wide"
            >
              Baixar folha
            </a>
          </div>
        </div>
      </div>
      <div className={styles['page-container']}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={styles['page-container--header']}>
              {imageLabel && (
                <button
                  className="btn btn--primary"
                  onClick={sendEssay}
                >
                  Enviar para revisão
                </button>
              )}
            </div>
            <div className={styles['page-container--wrapper']}>
              {imageLabel ? (
                <div className={styles['box-container']}>
                  <div className={styles['box-container--header']}>
                    <span className={styles['box-container--header-title']}>Foto da sua redação</span>
                    <label className="form__custom-file-input u-d-inline-block">
                      <input
                        ref={fileInputRef}
                        type="file"
                        name="image"
                        id="image"
                        accept=".jpg, .jpeg, .png, .heic, .heif"
                        onChange={transcribeEssay}
                      />
                      <span style={{ textDecoration: 'underline' }}>Enviar outra foto</span>
                    </label>
                  </div>
                  <div className="skill-evaluation__comment-item">
                    <img
                      src={imageLabel}
                      alt="imagem da redação"
                      className="u-cursor-pointer"
                      onClick={() => setOpenImgModal(true)}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles['box-container']}>
                  <div className={styles['box-container--form']}>
                    <div className={styles['box-container--content']}>
                      <label className="form__custom-file-input ">
                        Clique no botão abaixo para enviar sua redação
                        <p className="u-mb-4">A foto deve estar jpg, jpeg, png, heic ou heif </p>
                        <input
                          type="file"
                          name="image"
                          id="image"
                          accept=".jpg, .jpeg, .png, .heic, .heif"
                          onChange={transcribeEssay}
                          disabled={imageLabel}
                        />
                        <span className={`btn btn--primary btn--wide ${imageLabel && 'disabled'}`}>
                          <FiCamera />
                          Enviar foto da redação
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {essayText && (
                <div className={styles['box-container']}>
                  <div className={styles['box-container--header']}>
                    <span className={styles['box-container--header-title']}>Revise o texto digitalizado</span>
                  </div>
                  <div className={styles['box-container--text-area']}>
                    <textarea
                      ref={textareaRef}
                      onBlur={() => setEssayText(textareaRef.current?.value)}
                    >
                      {essayText}
                    </textarea>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <SimpleModal
        show={openErrorModal}
        onClose={() => setOpenErrorModal(false)}
        contentClassName="simple-modal__content--sm"
      >
        <div style={{ textAlign: 'center' }}>
          <span className={styles['modal-title']}>Arquivo inválido</span>
          <p>A imagem enviada não parecer ser de uma redação. Envie uma imagem válida para seguirmos com a correção</p>
          <label className="form__custom-file-input u-d-inline-block">
            <input
              type="file"
              name="image"
              id="image"
              accept=".jpg, .jpeg, .png, .heic, .heif"
              onChange={transcribeEssay}
            />
            <span className="btn btn--primary btn--wide">Enviar outra foto</span>
          </label>
        </div>
      </SimpleModal>
      <SimpleModal
        show={openImgModal}
        onClose={() => setOpenImgModal(false)}
        contentClassName="simple-modal__content--lg"
      >
        <img
          src={imageLabel}
          alt="prova digitalizada"
        />
      </SimpleModal>
      <SimpleModal
        show={openLoaderModal}
        onClose={() => setOpenLoaderModal(false)}
      >
        <div className={styles['modal']}>
          <Avatar
            className="avatar--md"
            src={imgTia}
            alt="T.I.A"
            data-tooltip-id="tia"
            data-tooltip-place="top-start"
            data-tooltip-variant="light"
          />
          <div>
            <span className={styles['modal-title']}>Aguarde enquanto digitalizamos sua redação</span>
            <p>Não feche essa tela</p>
            <Loader />
          </div>
        </div>
      </SimpleModal>
    </div>
  );
}
