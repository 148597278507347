import React from 'react';
import { t } from 'i18next';
import moment from 'moment';

export default function StepTitle(props) {
  const { step, form } = props;

  const titleTypeOptions = [
    { id: 1, name: 'teacher', label: 'Será definido por mim', value: false },
    { id: 2, name: 'student', label: 'Será definido pelo estudante', value: true }
  ];

  return (
    step === 'titulo' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          {/* <h2 className="new-questions__title">O desafio de combater o preconceito linguístico no Brasil</h2> */}
          <h2 className="new-questions__title">{form?.values?.topic}</h2>
        </div>

        {/* <fieldset className="form__row">
          <legend className="form__label">*Título da redação</legend>

          {titleTypeOptions.map(item => (
            <div
              className="form__check form__check--inline"
              key={item.id}
            >
              <input
                className="form__check-input"
                type="radio"
                name={item.name}
                id={`${item.name}-${item.id}`}
                value={item.name}
                checked={form.values.title_defined_by_student === item.value}
                onChange={() => form.setFieldValue('title_defined_by_student', item.value)}
              />

              <label htmlFor={`${item.name}-${item.id}`}>{item.label}</label>
            </div>
          ))}

          {!form.values.title_defined_by_student && (
            <input
              className="form__control fadeIn"
              aria-label="Escreva o título da redação"
              placeholder="Escreva o título da redação"
              id="title"
              name="title"
              type="text"
              value={form?.values?.title}
              onChange={e => form?.setFieldValue('title', e.target.value)}
            />
          )}
          {form.touched.title && form.errors.title && <span style={{ color: 'red' }}>{form.errors.title}</span>}
        </fieldset> */}

        <div className="form__row form__row--columns">
          <div className="form__col">
            <label
              className="form__label"
              htmlFor="delivery_date"
            >
              *Data de publicação
            </label>
            <input
              className="form__control"
              id="publish_at"
              name="publish_at"
              type="date"
              onChange={e => form.setFieldValue('publish_at', e.target.value)}
              value={moment(form?.values?.publish_at).format('YYYY-MM-DD')}
            />
            {form.touched.publish_at && form.errors.publish_at && <span style={{ color: 'red' }}>{form.errors.publish_at}</span>}
          </div>
          <div className="form__col">
            <label
              className="form__label"
              htmlFor="delivery_date"
            >
              *Prazo de entrega
            </label>
            <input
              className="form__control"
              id="delivery_date"
              name="delivery_date"
              type="date"
              onChange={e => form.setFieldValue('delivery_date', e.target.value)}
              value={moment(form?.values?.delivery_date).format('YYYY-MM-DD')}
            />
            {form.touched.delivery_date && form.errors.delivery_date && <span style={{ color: 'red' }}>{form.errors.delivery_date}</span>}
          </div>
        </div>

        <fieldset className="form__row">
          <legend className="form__label">Modo de correção</legend>

          <p>Por padrão, as redações são corrigidas automaticamente pela plataforma, mas você pode revisar os comentários e nota antes de enviar para os alunos</p>
        </fieldset>
        <fieldset className="form__row">
          <legend className="form__label">Quantidade de envios do mesmo tema </legend>

          <input
            type="checkbox"
            id="multiple-submissions"
            name="multiple-submissions"
            className="form__check-input"
            checked={form?.values?.['allow_multiple_submissions']}
            onClick={() => form.setFieldValue('allow_multiple_submissions', !form?.values?.['allow_multiple_submissions'])}
          />
          <label for="multiple-submissions">Permitir que os estudantes enviem mais de uma versão do mesmo tema</label>
        </fieldset>

        <span className="form__text u-mt-3">
          <strong>* {t('warning.requiredField')}</strong>
        </span>
      </div>
    )
  );
}
