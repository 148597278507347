import React, { useState, createContext, useEffect } from 'react';
import { ApiClient, ApiProvider } from 'jsonapi-react';
import normalize from 'json-api-normalizer';
import toast from 'react-hot-toast';
import api, { baseURL } from '../services/api';
import schema from '../../schema';
import { useTranslation } from 'react-i18next';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  // State storing user session specific data.
  const { t } = useTranslation();
  const [sessionData, setSessionData] = useState(() => JSON.parse(localStorage.getItem('session')));
  const [isSubmittingSignIn, setIsSubmittingSignIn] = useState(false);
  const [ssoToken, setSsoToken] = useState(localStorage.getItem('token'));
  const [connections, setConnections] = useState(() => JSON.parse(localStorage.getItem('connections')));
  const [dbNumber, setDbNumber] = useState(localStorage.getItem('DBNumber'));

  const apiClient = new ApiClient({ url: baseURL(), schema });

  if (sessionData && sessionData.user) {
    const authToken = sessionData.user['auth-token'] || sessionData.user.authToken;
    apiClient.addHeader('Authorization', `Bearer ${authToken}`);
  }

  // TEMPORARY: Generic context state manipulation is not recommended.
  // We should use specific state manipulation functions with descriptive names like signIn,
  // or signOut.
  const setSession = session => {
    localStorage.setItem('session', JSON.stringify(session));
    setSessionData(session);
  };

  const storeSsoToken = token => {
    localStorage.setItem('token', token);
    setSsoToken(token);
  };

  const storeConnections = connections => {
    localStorage.setItem('connections', JSON.stringify(connections));
    setConnections(connections);
  };

  const storeDBNumber = dbNumber => {
    localStorage.setItem('DBNumber', dbNumber);
    setDbNumber(dbNumber);
  };

  const sanitizeResponse = data => {
    const normalizedResponse = normalize({
      data: {
        id: String(data.id),
        type: 'user',
        attributes: data
      }
    });

    Object.keys(normalizedResponse).map(key => {
      return (normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes);
    });

    if (normalizedResponse.user?.image?.url) {
      normalizedResponse.user.image.url = `${normalizedResponse.user?.image?.url}?t=${new Date().getTime()}`;
    }

    normalizedResponse.user.authToken = normalizedResponse.user.authToken || sessionData?.user?.authToken;
    return normalizedResponse.user;
  };

  const signIn = async values => {
    setIsSubmittingSignIn(true);

    if (values.email) {
      values.email = values.email.toLowerCase();
    }

    const toastLoading = toast.loading(t('loader.loading'));

    return await api
      .post('authenticate', values)
      .then(response => {
        const normalizedResponse = normalize(response);

        Object.keys(normalizedResponse).map(key => {
          return (normalizedResponse[key] = normalizedResponse[key][Object.keys(normalizedResponse[key])].attributes);
        });

        toast.dismiss(toastLoading);
        setSession(normalizedResponse);
      })
      .catch(error => {
        toast.dismiss(toastLoading);
        setIsSubmittingSignIn(false);
        console.error(error);
        toast.error('Email ou senha inválidos');
        return error;
      });
  };

  const signOut = async () => {
    localStorage.removeItem('session');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('token');
    localStorage.removeItem('connections');
    localStorage.removeItem('DBNumber');

    const sso = process.env.REACT_APP_SSO_API_URL;
    const ssoClient = process.env.REACT_APP_SSO_CLIENT_URL;
    const ssoEtipi = process.env.REACT_APP_ETIPI_SSO_API_URL;

    window.location.pathname = '/login';

    if (ssoClient) {
      window.location.replace(`${ssoClient}/logout`);
    } else {
      window.location.pathname = '/login';
    }
  };

  return (
    <SessionContext.Provider
      value={{
        storeDBNumber,
        dbNumber,
        signIn,
        isSubmittingSignIn,
        signOut,
        sanitizeResponse,
        session: sessionData,
        setSession,
        connections,
        ssoToken,
        storeSsoToken,
        storeConnections
      }}
    >
      <ApiProvider client={apiClient}>{children}</ApiProvider>
    </SessionContext.Provider>
  );
};
export const UserConsumer = SessionContext.Consumer;
