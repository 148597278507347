import React from 'react';
import Modal from 'app/components/Modal';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import { AiOutlineTrophy } from 'react-icons/ai';
import * as Fi from 'react-icons/fi';
import moment from 'moment';
import { t } from 'i18next';

export default function CertificateModal(props) {
  const { show, onClose, currentCertificate } = props;

  const appName = process.env.REACT_APP_NAME;
  const logo = require(`app/images/${appName}/horizontal-logo.svg`);
  const certificateDate = currentCertificate?.['created-at'];
  const parts = certificateDate?.split('/');
  const day = parseInt(parts?.[0]);
  const month = parseInt(parts?.[1]);
  const year = parseInt(parts?.[2]);
  const monthsNames = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
  const monthName = monthsNames[month - 1];
  const formattedDate = `${day} de ${monthName} de ${year}`;

  function getIcon(icon) {
    switch (icon) {
      case 'FiVideo':
        return <Fi.FiVideo className="certificate__kpi-icon" />;
      case 'FiMousePointer':
        return <Fi.FiMousePointer className="certificate__kpi-icon" />;
      case 'FiEdit3':
        return <Fi.FiEdit3 className="certificate__kpi-icon" />;
      case 'FiCheckCircle':
        return <Fi.FiCheckCircle className="certificate__kpi-icon" />;
    }
  }

  function getEnvName() {
    switch (appName) {
      case 'unisescoop': 
        return 'Unisescoop';
      case 'demo':
        return 'Demonstração';
      case 'encceja':
        return 'Pré-Encceja';
      case 'quartosecretariado':
        return 'Quarto Secretariado';
      case 'retina':
        return 'Projeto Retina';
      case 'iesb':
        return 'IESB';
      case 'siape':
        return 'Siape';
      case 'fic':
        return 'FIC';
      case 'brasilstartups':
        return 'Brasil Startups';
      case 'estudologia':
        return 'Estudologia';
      case 'inkluziva':
        return 'inkluziva';
      case 'canaleducacao':
        return 'canaleducacao';
      case 'egepi':
        return 'egepi';
      case 'seducpi':
        return 'seducpi';
      case 'oros':
        return 'oros';
    }
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--lg"
    >
      <div className="certificate">
        <div className="certificate__header">
          <img
            className="certificate__img"
            src={defaultImage}
            alt="imagem"
          />
          <img
            className="certificate__logo"
            src={logo}
            alt="logotipo"
          />
          <div className="certificate__inner-header">
            <h2 className="certificate__title">{t('lms.certificateTitle')}</h2>
            <span className="certificate__icon">
              <AiOutlineTrophy />
            </span>
          </div>
        </div>

        <div className="certificate__body">
          <div
            className="certificate__text"
            dangerouslySetInnerHTML={{
              __html: t(currentCertificate?.['certificate-type'] === 'Curso' ? 'lms.certificateTextCourse' : 'lms.certificateText', {
                userName: currentCertificate?.user.name,
                moduleTitle: currentCertificate?.['learning-system']?.title,
                courseTitle: currentCertificate?.['learning-system']?.course.title,
                day,
                monthName,
                year,
                formattedDate,
                envName: getEnvName()
              })
            }}
          ></div>

          <div className="certificate__kpis">
            {currentCertificate?.info.map(item => {
              return (
                <div className="certificate__kpi">
                  {getIcon(item.icon)}
                  <div className="certificate__kpi-inner">
                    <h3 className="certificate__kpi-value">{item.count}</h3>
                    <p className="certificate__kpi-label">{item.label}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <p className="certificate__text">
            <small>{t('lms.certificateTextSmall')}</small>
          </p>
        </div>

        <div className="certificate__footer">
          <p className="certificate__text">
            <small>
              Brasília,
              <span
                dangerouslySetInnerHTML={{
                  __html: t('datesForm.formattedDate', {
                    day: day,
                    monthName: monthName,
                    year: year
                  })
                }}
              ></span>
            </small>
          </p>
        </div>
      </div>
    </Modal>
  );
}
