import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export default function OverviewStudent(props) {
  const { kpis } = props;

  return (
    <div className="report-widget">
      <div className="report-widget__header">
        <h3 className="report-widget__title">Visão geral</h3>
      </div>

      <div className="report-widget__body">
        <div className="report-kpi-card-wrapper">
          {kpis.map(kpi => (
            <div
              className={`report-kpi-card ${kpi.variation}`}
              key={kpi.id}
            >
              {kpi.help && (
                <>
                  <FiHelpCircle
                    className="report-kpi-card__help-icon"
                    data-tooltip-id={kpi.id}
                    data-tooltip-content={kpi.help}
                  />
                  <ReactTooltip
                    id={kpi.id}
                    className="tooltip"
                  />
                </>
              )}
              <h3 className="report-kpi-card__title">{kpi.title}</h3>
              <p
                className="report-kpi-card__value"
                title={kpi.value}
              >
                {kpi.value.toFixed(0)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
