import React from 'react';
import RadarChart from './RadarChart';

export default function RadarChartWidget({ apiData = [] }) {
  const competenceLabels = {
    c1: 'C1- Domínio da escrita formal da língua portuguesa',
    c2: 'C2- Compreender o tema e não fugir do que é proposto',
    c3: 'C3- Organizar e articular as ideias',
    c4: 'C4- Desenvolver argumentos de forma clara',
    c5: 'C5- Propor solução para o problema'
  };

  const data = apiData;

  const radarData = data?.map(competence => {
    return competence.average;
  });

  return (
    <div className="report-widget">
      <div className="report-widget__header">
        <div className="report-widget__inner">
          <h3 className="report-widget__title">Pontuação média das competências</h3>
          <p className="report-widget__subtitle">Para a construção do gráfico, foi considerada a média de cada competência em todas as redações enviadas</p>
        </div>
      </div>

      <div className="report-widget__body">
        <RadarChart
          categories={Object.values(competenceLabels)}
          data={radarData}
        />
      </div>
    </div>
  );
}
