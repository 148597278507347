import React, { useState, useEffect } from 'react';
import Highcharts, { color } from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { max } from 'moment';

export default function RadarChart({ categories, data }) {
  const [screenWidth, setscreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setscreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  HC_more(Highcharts);

  const options = {
    chart: {
      polar: true,
      type: 'area',
      marginBottom: 100
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    xAxis: {
      categories: categories,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          whiteSpace: 'nowrap',
          width: 160,
          textOverflow: 'ellipsis'
        }
      }
    },
    yAxis: {
      min: 0,
      max: 200,
      gridLineInterpolation: 'polygon'
    },
    series: [
      {
        name: 'Pontuação média das competências',
        data: data,
        color: '#7cb5ec'
      },
      {
        name: 'C1- Domínio da escrita formal da língua portuguesa',
        color: 'rgba(0, 0, 0, 0)'
      },
      {
        name: 'C2- Compreender o tema e não fugir do que é proposto',
        color: 'rgba(0, 0, 0, 0)'
      },
      {
        name: 'C3- Organização e defesa dos argumentos',
        color: 'rgba(0, 0, 0, 0)'
      },
      {
        name: 'C4- Conhecimento dos mecanismos linguísticos',
        color: 'rgba(0, 0, 0, 0)'
      },
      {
        name: 'C5- Apresentar uma proposta de intervenção',
        color: 'rgba(0, 0, 0, 0)'
      }
    ],
    legend: {
      enabled: true,
      align: screenWidth >= 1024 ? 'right' : 'bottom',
      verticalAlign: screenWidth >= 1024 ? 'middle' : 'bottom',
      layout: 'vertical'
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick: function () {
            return false;
          }
        }
      }
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
}
